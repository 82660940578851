import { getLoginUrl } from '../Utils';

class InSylvaKeycloakClient {
  async post(path, requestContent) {
    // const access_token = sessionStorage.getItem("access_token");
    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded',
      // "Access-Control-Allow-Methods": "GET,HEAD,PUT,PATCH,POST,DELETE",
      // "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept, Authorization"
    };
    /*
        if (access_token) {
            headers["Authorization"] = "Bearer " + access_token
        }
        */
    let formBody = [];
    for (const property in requestContent) {
      const encodedKey = encodeURIComponent(property);
      const encodedValue = encodeURIComponent(requestContent[property]);
      formBody.push(encodedKey + '=' + encodedValue);
    }
    formBody = formBody.join('&');
    const response = await fetch(`${this.baseUrl}${path}`, {
      method: 'POST',
      headers,
      body: formBody,
      mode: 'cors',
    });
    if (response.ok === true) {
      // ok
    } else {
      await this.logout();
      sessionStorage.removeItem('user_id');
      sessionStorage.removeItem('access_token');
      sessionStorage.removeItem('refresh_token');
      window.location.replace(getLoginUrl() + '?requestType=search');
    }
    if (response.statusText === 'No Content') {
      // ok
    } else {
      return await response.json();
    }
  }

  async refreshToken({
    realm = this.realm,
    client_id = this.client_id,
    // client_secret : 'optional depending on the type of client',
    grant_type = 'refresh_token',
    refresh_token,
  }) {
    const path = `/auth/realms/${realm}/protocol/openid-connect/token`;
    const token = await this.post(`${path}`, {
      client_id,
      grant_type,
      refresh_token,
    });
    return { token };
  }

  async logout({ realm = this.realm, client_id = this.client_id }) {
    const refresh_token = sessionStorage.getItem('refresh_token');
    const path = `/auth/realms/${realm}/protocol/openid-connect/logout`;
    if (refresh_token) {
      await this.post(`${path}`, {
        client_id,
        refresh_token,
      });
    }
  }
}

InSylvaKeycloakClient.prototype.baseUrl = null;
InSylvaKeycloakClient.prototype.client_id = null;
InSylvaKeycloakClient.prototype.grant_type = null;
InSylvaKeycloakClient.prototype.realm = null;
export { InSylvaKeycloakClient };
