class InSylvaSearchClient {
  async post(method, path, requestContent) {
    const headers = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      Authorization: `Bearer ${this.token}`,
      'Access-Control-Max-Age': 86400,
      // 'Access-Control-Allow-Methods': 'GET, POST, PUT, PATCH',
    };
    const response = await fetch(`${this.baseUrl}${path}`, {
      method,
      headers,
      body: JSON.stringify(requestContent),
      mode: 'cors',
    });
    return await response.json();
  }

  /* async search(query, indices) {
        const indicesId = []
        indices.forEach(index => {
            indicesId.push(index.index_id)
        })
        const path = `/search`;
        const result = await this.post('POST', `${path}`, {
            indicesId,
            query
        });
        return result;
    } */

  async search(queries) {
    let finalResult = [];

    for (let i = 0; i < queries.length; i++) {
      const indicesId = queries[i].indicesId;
      const query = queries[i].query;
      const path = `/scroll-search`;
      const result = await this.post('POST', `${path}`, {
        indicesId,
        query,
      });
      if (!result.statusCode) {
        finalResult.push(...result);
      }
    }
    return finalResult;
  }

  async count(queries) {
    let finalResult = 0;
    for (let i = 0; i < queries.length; i++) {
      const indicesId = queries[i].indicesId;
      const query = queries[i].query;
      const path = `/count`;
      const result = await this.post('POST', `${path}`, {
        indicesId,
        query,
      });
      finalResult = finalResult + result.count;
    }
    return finalResult;
  }
}

InSylvaSearchClient.prototype.baseUrl = null;
InSylvaSearchClient.prototype.token = null;
export { InSylvaSearchClient };
