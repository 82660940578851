export class SearchField {
  constructor(name, type, values, isValidated, sources) {
    this.name = name;
    this.type = type;
    this.values = values;
    this.isValidated = isValidated;
    this.sources = sources;
  }
}

export const getLoginUrl = () => {
  return process.env.REACT_APP_IN_SYLVA_LOGIN_PORT
    ? `${process.env.REACT_APP_IN_SYLVA_LOGIN_HOST}:${process.env.REACT_APP_IN_SYLVA_LOGIN_PORT}`
    : `${window._env_.REACT_APP_IN_SYLVA_LOGIN_HOST}`;
};

export const removeNullFields = (standardFields) => {
  let standardFieldsNoNull = standardFields;
  let nullIndex;
  if (standardFields.length) {
    for (let i = 0; i < standardFieldsNoNull.length; i++) {
      if (!standardFieldsNoNull[i].field_name) {
        nullIndex = i;
      }
    }
    standardFieldsNoNull = removeArrayElement(standardFieldsNoNull, nullIndex);
  }

  return standardFieldsNoNull;
};

export const getSections = (fields) => {
  let sections = [];
  let sectionsLabels = [];
  fields.forEach((field) => {
    if (field.field_name && field.field_name.includes('.')) {
      sections.push(field.field_name.split('.', 1)[0]);
    }
  });
  sections = [...new Set(sections)];
  sections.forEach((section) => {
    sectionsLabels.push({
      label: section.replace(/_|\./g, ' '),
    });
  });
  return sectionsLabels;
};

export const getFieldsBySection = (fields, fieldSection) => {
  let filteredFields = [];
  fields.forEach((field) => {
    if (
      field.field_name &&
      field.field_name.split('.', 1)[0].replace(/_|\./g, ' ') === fieldSection.label
    ) {
      if (field.sources.length) {
        filteredFields.push({
          label: field.field_name
            .substring(field.field_name.indexOf('.') + 1)
            .replace(/_|\./g, ' '),
          color: 'danger',
        });
      } else {
        filteredFields.push({
          label: field.field_name
            .substring(field.field_name.indexOf('.') + 1)
            .replace(/_|\./g, ' '),
          color: 'primary',
        });
      }
    }
  });
  return filteredFields;
};

export const updateArrayElement = (array, index, value) => {
  let newArray = array.slice();
  newArray[index] = value;
  return newArray;
};

export const removeArrayElement = (array, index) => {
  let newArray = array.slice();
  newArray.splice(index, 1);
  return newArray;
};

export const updateSearchFieldValues = (searchFields, index, values) => {
  let newSearchFields = searchFields.slice();
  newSearchFields[index].values = values;
  return newSearchFields;
};

export const getUrlParams = () => {
  let lets = {};
  window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
    lets[key] = value;
  });
  return lets;
};

export const getUrlParam = (parameter, defaultvalue) => {
  let urlParameter = defaultvalue;
  if (window.location.href.indexOf(parameter) > -1) {
    urlParameter = getUrlParams()[parameter];
  }
  return urlParameter;
};

export const changeNameToLabel = (object) => {
  object.label = object.name;
  delete object.name;
  return object;
};

export const redirect = (url, condition = true) => {
  if (condition) {
    window.location.replace(url);
  }
};

export const tokenTimedOut = (validityDurationInMin) => {
  const timeSinceLastRefresh = Date.now() - sessionStorage.getItem('token_refresh_time');
  if (timeSinceLastRefresh > validityDurationInMin * 60000) {
    return true;
  }
  return false;
};

export const createSqlSelectQueries = (indexList, indexes, conditions, fields = '*') => {
  let queries = [];
  let query = '';

  if (!conditions) {
    throw new Error('Conditions are empty, SQL query cannot be created.');
  }
  if (!indexes) {
    indexes = indexList;
  }
  indexes.forEach((element) => {
    query = 'select ' + fields + ' from ' + element.label + ' where ' + conditions;
    queries.push(query);
  });
  return queries;
};

const splitString = (str, bracketOpenRegex, bracketCloseRegex, separatorRegex) => {
  let strToSplit = str;
  let requestTokens = str.split('');
  let openBracketCount = 0;
  let startSplitIndex = 0;
  let splitStr = [];
  for (let i = 0; i < requestTokens.length; i++) {
    requestTokens.forEach((token, index) => {});
    switch (true) {
      case bracketOpenRegex.test(requestTokens[i]):
        openBracketCount = openBracketCount + 1;
        if (openBracketCount === 1) startSplitIndex = i + 1;
        break;
      case bracketCloseRegex.test(requestTokens[i]):
        openBracketCount = openBracketCount - 1;
        if (openBracketCount === 0) {
          splitStr.push(strToSplit.substring(startSplitIndex, i).trim());
          startSplitIndex = i + 1;
        }
        break;
      case separatorRegex.test(requestTokens[i]):
        if (openBracketCount === 0) {
          let tmpStr = strToSplit.substring(startSplitIndex, i).trim();
          if (tmpStr !== '') {
            splitStr.push(tmpStr);
          }
          splitStr.push(strToSplit[i]);
          startSplitIndex = i + 1;
        }
        break;
      default:
    }
  }
  if (startSplitIndex < requestTokens.length) {
    splitStr.push(strToSplit.substring(startSplitIndex, requestTokens.length).trim());
  }
  return splitStr;
};

// const buildDslClause = (clause, query, fields) => {
//     let tmpQuery = query
const buildDslClause = (clause, fields) => {
  let clauseStr = clause
    .replace(/ or /g, ' | ')
    .replace(/ and /g, ' & ')
    .replace(/{|}/g, '')
    .trim();
  let splitClause = splitString(clauseStr, /\(/, /\)/, /&|\|/);
  let firstValue, secondValue, thirdValue;

  // tmpQuery = `${tmpQuery} { "bool": { "should": [ `
  let tmpQuery = ` { "bool": { "should": [ `;

  for (let i = 0; i < splitClause.length; i++) {
    switch (true) {
      case splitClause[i].includes('<='):
        tmpQuery = `${tmpQuery} { "range": `;
        firstValue = splitClause[i].substring(0, splitClause[i].indexOf('<=') - 1).trim();
        secondValue = splitClause[i]
          .substring(splitClause[i].indexOf('<=') + 2, splitClause[i].length)
          .trim();
        if (secondValue.includes('<=')) {
          //case value <= field <= value
          thirdValue = secondValue
            .substring(secondValue.indexOf('<=') + 2, secondValue.length)
            .trim();
          secondValue = secondValue.substring(0, secondValue.indexOf('<=') - 1).trim();
          tmpQuery = `${tmpQuery} { "${secondValue}": { "gte": "${firstValue}", "lte": "${thirdValue}" } } }, `;
        } else {
          //case field <= value
          tmpQuery = `${tmpQuery} { "${firstValue}": { "lte": "${secondValue}" } } }, `;
        }
        break;
      case splitClause[i].includes('>='):
        tmpQuery = `${tmpQuery} { "range": `;
        firstValue = splitClause[i].substring(0, splitClause[i].indexOf('>=') - 1).trim();
        secondValue = splitClause[i]
          .substring(splitClause[i].indexOf('>=') + 2, splitClause[i].length)
          .trim();
        tmpQuery = `${tmpQuery} { "${firstValue}": { "gte": "${secondValue}" } } }, `;
        break;
      default:
        let fieldName = splitClause[i]
          .substring(0, splitClause[i].indexOf('=') - 1)
          .trim();
        let field = fields.find((item) => item.field_name === fieldName);
        if (field) {
          switch (field.field_type) {
            case 'Date':
            case 'Numeric':
              tmpQuery = `${tmpQuery} { "term": { "${fieldName}": "${splitClause[i].substring(splitClause[i].indexOf('=') + 1, splitClause[i].length).trim()}" } }, `;
              break;
            default:
              let values = splitClause[i]
                .substring(splitClause[i].indexOf('=') + 1, splitClause[i].length)
                .split(',');
              values.forEach((value) => {
                tmpQuery = `${tmpQuery} { "match": { "${fieldName}": { "query": "${value.trim()}", "operator": "AND" } } }, `;
              });
          }
        }
    }
  }
  if (tmpQuery.endsWith(', ')) {
    tmpQuery = tmpQuery.substring(0, tmpQuery.length - 2);
  }
  tmpQuery = `${tmpQuery} ] } }, `;
  return tmpQuery;
};

// const buildDslQuery = (request, query, fields) => {
//     let tmpQuery = query
const buildDslQuery = (request, fields) => {
  let requestStr = request.replace(/ OR /g, ' | ').replace(/ AND /g, ' & ').trim();
  let splitRequest = splitString(requestStr, /\(/, /\)/, /&|\|/);
  let tmpQuery = '';

  if (splitRequest.length === 1) {
    // tmpQuery = `${tmpQuery} { "bool": { "must": [ `
    tmpQuery = ` { "bool": { "must": [ `;
  } else {
    for (let i = 0; i < splitRequest.length; i++) {
      if (splitRequest[i] === '|') {
        // tmpQuery = `${tmpQuery} { "bool": { "should": [ `
        tmpQuery = ` { "bool": { "should": [ `;
        break;
      } else if (splitRequest[i] === '&') {
        // tmpQuery = `${tmpQuery} { "bool": { "must": [ `
        tmpQuery = ` { "bool": { "must": [ `;
        break;
      }
    }
  }

  for (let i = 0; i < splitRequest.length; i++) {
    if (!splitRequest[i].includes('|') && !splitRequest[i].includes('&')) {
      // tmpQuery = buildDslClause(splitRequest[i], tmpQuery, fields)
      tmpQuery = `${tmpQuery} ${buildDslClause(splitRequest[i], fields)}`;
    } else if (
      (splitRequest[i].includes('|') || splitRequest[i].includes('&')) &&
      splitRequest[i].length > 1
    ) {
      // tmpQuery = buildDslQuery(splitRequest[i], tmpQuery, fields)
      tmpQuery = `${tmpQuery} ${buildDslQuery(splitRequest[i], fields)}`;
    }
  }
  if (tmpQuery.endsWith(', ')) {
    tmpQuery = tmpQuery.substring(0, tmpQuery.length - 2);
  }
  tmpQuery = `${tmpQuery} ] } }, `;
  return tmpQuery;
};

export const createAdvancedQuery = (fields, searchRequest) => {
  let sourceParam = `"_source": [`;
  fields.forEach((field) => {
    sourceParam = `${sourceParam} "${field.field_name}", `;
  });
  if (sourceParam.endsWith(', ')) {
    sourceParam = sourceParam.substring(0, sourceParam.length - 2);
  }
  sourceParam = `${sourceParam}],`;
  let query = `{ ${sourceParam} "query": `;
  query = buildDslQuery(searchRequest, query, fields);
  if (query.endsWith(', ')) {
    query = query.substring(0, query.length - 2);
  }
  query = query + '}';
  const queryObject = JSON.parse(query);
  return queryObject;
};

//find an array in a multidimensional array and return the index
const findArray = (multiArray, array) => {
  let found = false;
  for (let index = 0; index < multiArray.length; index++) {
    if (multiArray[index].length === 0 && array.length === 0) {
      return index;
    }
    if (multiArray[index].length === array.length) {
      for (let i = 0; i < array.length; i++) {
        found = array[i] === multiArray[index][i];
      }
    }
    if (found) {
      return index;
    }
  }
  return -1;
};

export const createAdvancedQueriesBySource = (
  fields,
  searchRequest,
  selectedSources,
  sources
) => {
  const queries = [];
  let fieldsLists = [[]];
  let sourcesLists = [[]];
  let queriedSourcesId = [];
  let noPolicySourcesId = [];
  const indicesLists = [];
  const publicFieldnames = [];
  const privateFields = [];
  /* fields.forEach(field => {
        if (field.ispublic) {
            publicFieldnames.push(field.field_name)
        } else {
            privateFields.push(field)
        }
    })

    if (selectedSources.length) {
        selectedSources.forEach(source => {
            queriedSourcesId.push(source.id)
        })
    } else {
        sources.forEach(source => {
            queriedSourcesId.push(source.id)
        })
    }

    noPolicySourcesId = queriedSourcesId

    //browse fields to create a map containing the fields available by source
    //ie : [field1, field2] - [source1], [field1, field3, field4] - [source2], [field1] - []
    //empty brackets means "the sources not affected by policies" at that point - this list is being compiled at the same time
    if (privateFields.length) {
        privateFields.forEach(field => {
            field.sources.forEach(sourceId => {
                if (queriedSourcesId.includes(sourceId)) {
                    const index = findArray(sourcesLists, [sourceId])
                    if (index >= 0) {
                        fieldsLists[index].push(field.field_name)
                    } else {
                        fieldsLists.push([field.field_name])
                        sourcesLists.push([sourceId])
                    }
                }
                //filter no policy sources
                if (noPolicySourcesId.includes(sourceId))
                    noPolicySourcesId = removeArrayElement(noPolicySourcesId, noPolicySourcesId.indexOf(sourceId))
            })
        })
        //add the public fields for every source
        fieldsLists.forEach(fieldList => {
            fieldList.push(...publicFieldnames)
        })
    }

    const indexOfAllSources = findArray(sourcesLists, [])
    //if there isn't any source with no policy, remove corresponding items in sourcesLists and fieldsLists
    if (!noPolicySourcesId.length) {
        sourcesLists = removeArrayElement(sourcesLists, indexOfAllSources)
        fieldsLists = removeArrayElement(fieldsLists, indexOfAllSources)
    } else {
        sourcesLists = updateArrayElement(sourcesLists, indexOfAllSources, noPolicySourcesId)
    }

    //get elastic indices from sources id
    sourcesLists.forEach(sourceList => {
        const indicesList = []
        sourceList.forEach(sourceId => {
            const source = sources.find(src => src.id === sourceId)
            indicesList.push(source.index_id)
        })
        indicesLists.push(indicesList)
    }) */

  fields.forEach((field) => {
    if (field.ispublic) {
      publicFieldnames.push(field.field_name);
    } else {
      privateFields.push(field);
    }
  });

  if (selectedSources.length) {
    selectedSources.forEach((source) => {
      queriedSourcesId.push(source.id);
    });
  } else {
    if (sources.length) {
      sources.forEach((source) => {
        queriedSourcesId.push(source.id);
      });
    }
  }

  noPolicySourcesId = queriedSourcesId;

  //browse fields to create a map containing the fields available by source
  //ie : [field1, field2] - [source1], [field1, field3, field4] - [source2], [field1] - []
  //empty brackets means "the sources not affected by policies" at that point
  if (privateFields.length) {
    privateFields.forEach((field) => {
      field.sources.forEach((sourceId) => {
        if (queriedSourcesId.includes(sourceId)) {
          const index = findArray(sourcesLists, [sourceId]);
          if (index >= 0) {
            fieldsLists[index].push(field.field_name);
          } else {
            fieldsLists.push([field.field_name]);
            sourcesLists.push([sourceId]);
          }
        }
        //filter no policy sources
        if (noPolicySourcesId.includes(sourceId)) {
          noPolicySourcesId = removeArrayElement(
            noPolicySourcesId,
            noPolicySourcesId.indexOf(sourceId)
          );
        }
      });
    });
    //add the public fields for every source
    fieldsLists.forEach((fieldList) => {
      fieldList.push(...publicFieldnames);
    });
  }

  const indexOfAllSources = findArray(sourcesLists, []);
  //if there is no source with no policy, remove corresponding items in sourcesLists and fieldsLists
  if (!noPolicySourcesId.length) {
    sourcesLists = removeArrayElement(sourcesLists, indexOfAllSources);
    fieldsLists = removeArrayElement(fieldsLists, indexOfAllSources);
  } else {
    sourcesLists = updateArrayElement(sourcesLists, indexOfAllSources, noPolicySourcesId);
    fieldsLists = updateArrayElement(fieldsLists, indexOfAllSources, publicFieldnames);
  }

  //get elastic indices from sources id
  sourcesLists.forEach((sourceList) => {
    const indicesList = [];
    sourceList.forEach((sourceId) => {
      const source = sources.find((src) => src.id === sourceId);
      indicesList.push(source.index_id);
    });
    indicesLists.push(indicesList);
  });

  const queryContent = buildDslQuery(searchRequest, fields);

  sourcesLists.forEach((sourcesArray, index) => {
    let sourceParam = `"_source": [`;
    fieldsLists[index].forEach((fieldname) => {
      sourceParam = `${sourceParam} "${fieldname}", `;
    });
    if (sourceParam.endsWith(', '))
      sourceParam = sourceParam.substring(0, sourceParam.length - 2);
    sourceParam = `${sourceParam}],`;
    let query = `{ ${sourceParam} "query": ${queryContent}`;
    if (query.endsWith(', ')) {
      query = query.substring(0, query.length - 2);
    }
    query = query + '}';
    queries.push({ indicesId: indicesLists[index], query: JSON.parse(query) });
  });
  return queries;
};

export const createBasicQueriesBySource = (
  fields,
  searchRequest,
  selectedSources,
  sources
) => {
  const queries = [];
  let fieldsLists = [[]];
  let sourcesLists = [[]];
  let queriedSourcesId = [];
  let noPolicySourcesId = [];
  const indicesLists = [];
  const publicFieldnames = [];
  const privateFields = [];

  fields.forEach((field) => {
    if (field.ispublic) {
      publicFieldnames.push(field.field_name);
    } else {
      privateFields.push(field);
    }
  });

  if (selectedSources.length) {
    selectedSources.forEach((source) => {
      queriedSourcesId.push(source.id);
    });
  } else {
    if (sources.length) {
      sources.forEach((source) => {
        queriedSourcesId.push(source.id);
      });
    }
  }

  noPolicySourcesId = queriedSourcesId;

  //browse fields to create a map containing the fields available by source
  //ie : [field1, field2] - [source1], [field1, field3, field4] - [source2], [field1] - []
  //empty brackets means "the sources not affected by policies" at that point
  if (privateFields.length) {
    privateFields.forEach((field) => {
      field.sources.forEach((sourceId) => {
        if (queriedSourcesId.includes(sourceId)) {
          const index = findArray(sourcesLists, [sourceId]);
          if (index >= 0) {
            fieldsLists[index].push(field.field_name);
          } else {
            fieldsLists.push([field.field_name]);
            sourcesLists.push([sourceId]);
          }
        }
        //filter no policy sources
        if (noPolicySourcesId.includes(sourceId))
          noPolicySourcesId = removeArrayElement(
            noPolicySourcesId,
            noPolicySourcesId.indexOf(sourceId)
          );
      });
    });
    //add the public fields for every source
    fieldsLists.forEach((fieldList) => {
      fieldList.push(...publicFieldnames);
    });
  }

  const indexOfAllSources = findArray(sourcesLists, []);
  //if there is no source with no policy, remove corresponding items in sourcesLists and fieldsLists
  if (!noPolicySourcesId.length) {
    sourcesLists = removeArrayElement(sourcesLists, indexOfAllSources);
    fieldsLists = removeArrayElement(fieldsLists, indexOfAllSources);
  } else {
    sourcesLists = updateArrayElement(sourcesLists, indexOfAllSources, noPolicySourcesId);
    fieldsLists = updateArrayElement(fieldsLists, indexOfAllSources, publicFieldnames);
  }

  //get elastic indices from sources id
  sourcesLists.forEach((sourceList) => {
    const indicesList = [];
    sourceList.forEach((sourceId) => {
      const source = sources.find((src) => src.id === sourceId);
      indicesList.push(source.index_id);
    });
    indicesLists.push(indicesList);
  });

  sourcesLists.forEach((sourcesArray, index) => {
    let sourceParam = `"_source": [`;
    fieldsLists[index].forEach((fieldname) => {
      sourceParam = `${sourceParam} "${fieldname}", `;
    });
    if (sourceParam.endsWith(', ')) {
      sourceParam = sourceParam.substring(0, sourceParam.length - 2);
    }
    sourceParam = `${sourceParam}],`;
    let query = `{ ${sourceParam} "query": { "multi_match": { "query": "${searchRequest}" } } }`;
    queries.push({ indicesId: indicesLists[index], query: JSON.parse(query) });
  });
  return queries;
};
