import React, { Fragment, useEffect, useState } from 'react';
import {
  EuiButton,
  EuiCallOut,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFlyout,
  EuiFlyoutBody,
  EuiIcon,
  EuiSpacer,
  EuiText,
  EuiTitle,
} from '@elastic/eui';
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import MUIDataTable from 'mui-datatables';
import JsonView from '@in-sylva/json-view';
import { updateArrayElement } from '../../Utils.js';

const download = require('downloadjs');

const getMuiTheme = () =>
  createTheme({
    overrides: {
      MUIDataTable: {
        root: {
          backgroundColor: '#fafbfc',
        },
        paper: {
          boxShadow: 'none',
        },
      },
    },
  });

const changeFlyoutState = (array, index, value, defaultValue) => {
  let newArray = new Array(array.length).fill(defaultValue);
  newArray[index] = value;
  return newArray;
};

const Results = (searchResults, search, basicSearch) => {
  const [resultsCol, setResultsCol] = useState([]);
  const [results, setResults] = useState([]);
  const [isFlyoutOpen, setIsFlyoutOpen] = useState([false]);
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    processData(searchResults);
    search.length ? setSearchQuery(search) : setSearchQuery(basicSearch);
  }, [searchResults, search, basicSearch]);

  const updateTableCell = (tableContent, value, colIndex, rowIndex) => {
    const updatedRow = updateArrayElement(tableContent[rowIndex], colIndex, value);
    return updateArrayElement(tableContent, rowIndex, updatedRow);
  };

  const closeAllFlyouts = (tableContent) => {
    const updatedResults = [];
    tableContent.forEach((tableRow, index) => {
      updatedResults.push(updateArrayElement(tableRow, 0));
    });
    return updatedResults;
  };

  const recordFlyout = (record, recordIndex, isOpen) => {
    if (isOpen) {
      return (
        <>
          <EuiFlyout
            onClose={() => {
              const updatedTable = updateTableCell(
                closeAllFlyouts(results),
                recordFlyout(record, recordIndex, false),
                0,
                recordIndex
              );
              const updatedArray = changeFlyoutState(
                isFlyoutOpen,
                recordIndex,
                false,
                false
              );
              setIsFlyoutOpen(updatedArray);
              setResults(updatedTable);
            }}
            aria-labelledby={recordIndex}
          >
            <EuiFlyoutBody>
              <EuiText size="s">
                <Fragment>
                  <JsonView
                    name="In-Sylva metadata"
                    collapsed={true}
                    iconStyle={'triangle'}
                    src={record}
                    enableClipboard={false}
                    displayDataTypes={false}
                  />
                </Fragment>
              </EuiText>
            </EuiFlyoutBody>
          </EuiFlyout>
          <EuiIcon type="eye" color="danger" />
        </>
      );
    }
  };

  const resultsGridOptions = {
    print: false,
    download: false,
    filter: true,
    filterType: 'dropdown',
    responsive: 'standard',
    selectableRows: 'none',
    selectableRowsOnClick: false,
    onRowSelectionChange: (rowsSelected, allRows) => {},
    onRowClick: (rowData, rowState) => {},
    onCellClick: (val, colMeta) => {
      // if (searchResults.hits.hits && colMeta.colIndex !== 0) {
      if (searchResults && colMeta.colIndex !== 0) {
        // const updatedTable = updateTableCell(closeAllFlyouts(results), recordFlyout(searchResults.hits.hits[colMeta.rowIndex]._source, colMeta.rowIndex, !isFlyoutOpen[colMeta.rowIndex]), 0, colMeta.rowIndex)
        const updatedTable = updateTableCell(
          closeAllFlyouts(results),
          recordFlyout(
            searchResults[colMeta.dataIndex],
            colMeta.dataIndex,
            !isFlyoutOpen[colMeta.dataIndex]
          ),
          0,
          colMeta.dataIndex
        );
        const updatedArray = changeFlyoutState(
          isFlyoutOpen,
          colMeta.dataIndex,
          !isFlyoutOpen[colMeta.dataIndex],
          false
        );
        setIsFlyoutOpen(updatedArray);
        setResults(updatedTable);
      }
    },
  };

  /*  const displayRecord = (record) => {
         let recordDisplay = []
         if (!!record) {
             const fields = Object.keys(record)
             fields.forEach(field => {
                 if (typeof record[field] != 'string') {
                     // const rndId = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
                     if (isNaN(field)) {
                         // const buttonContent = `"${field}"`
                         let isStrArray = false
                         if (Array.isArray(record[field])) {
                             isStrArray = true
                             record[field].forEach(item => {
                                 if (typeof item != 'string')
                                     isStrArray = false
                             })
                         }
                         if (isStrArray) {
                             recordDisplay.push(
                                 <>
                                     <h3>
                                         &emsp;{field}
                                     </h3>
                                     {displayRecord(record[field])}
                                 </>
                             )
                         } else {
                             recordDisplay.push(
                                 <>
                                     <EuiSpacer size="s" />
                                     <EuiPanel paddingSize="s">
                                         <EuiAccordion id={Math.random().toString()} buttonContent={field}>
                                             <EuiText size="s">
                                                 {displayRecord(record[field])}
                                             </EuiText>
                                         </EuiAccordion>
                                     </EuiPanel>
                                 </>
                             )
                         }
                     } else {
                         recordDisplay.push(
                             <>
                                 {displayRecord(record[field])}
                             </>
                         )
                         if (fields[fields.indexOf(field) + 1])
                             recordDisplay.push(
                                 <>
                                     <EuiSpacer size="m" />
                                     <hr />
                                 </>
                             )
                     }
                 } else {
                     if (isNaN(field)) {
                         recordDisplay.push(
                             <>
                                 <h3>
                                     &emsp;{field}
                                 </h3>
                                 <EuiTextColor color="secondary">&emsp;&emsp;{record[field]}</EuiTextColor>
                             </>
                         )
                     } else {
                         recordDisplay.push(
                             <>
                                 <EuiSpacer size="s" />
                                 <EuiTextColor color="secondary">&emsp;&emsp;{record[field]}</EuiTextColor>
                             </>
                         )
                     }
                 }
             })
             return recordDisplay
         }
     }

     const recordFlyout = (record, recordIndex, isFlyoutOpen, setIsFlyoutOpen) => {
         let flyout
         if (isFlyoutOpen[recordIndex]) {
             // const flyOutContent = ReactHtmlParser(displayRecord(record, 1))
             const flyOutStr = displayRecord(record)
             // const flyOutContent = parse(flyOutStr, { htmlparser2: { lowerCaseTags: false } })
             const flyout = (
                 <>
                     <EuiFlyout
                         onClose={() => {
                             // setIsFlyoutOpen(updateArrayElement(isFlyoutOpen, recordIndex, false))
                             // updateResultsCell(false, 0, recordIndex)
                             const updatedArray = changeFlyoutState(isFlyoutOpen, recordIndex, !isFlyoutOpen[recordIndex], false)
                             setIsFlyoutOpen(updatedArray)
                         }}
                         aria-labelledby={recordIndex}>
                         <EuiFlyoutBody>
                             <EuiText size="s">
                                 <Fragment>
                                     {flyOutStr}
                                 </Fragment>
                             </EuiText>
                         </EuiFlyoutBody>
                     </EuiFlyout>
                     <EuiIcon type='eye' color='danger' />
                 </>
             );
             return (flyout)
         }
     } */

  /* const viewButton = (record, recordIndex, isFlyoutOpenIndex, isFlyoutOpen, setIsFlyoutOpen) => {
        return (
            <>
                <EuiButtonIcon
                    size="m"
                    color="success"
                    onClick={() => {
                        const flyOutArray = updateArrayElement(isFlyoutOpen, recordIndex, !isFlyoutOpen[recordIndex])
                        setIsFlyoutOpen(flyOutArray)
                        updateResultsCell(!isFlyoutOpen[recordIndex], isFlyoutOpenIndex, recordIndex)
                    }}
                    iconType="eye"
                    title="View record"
                />
                {recordFlyout(record, recordIndex, isFlyoutOpen, setIsFlyoutOpen)}
            </>
        )
    } */

  const processData = (metadata) => {
    // if (metadata && metadata.hits) {
    if (metadata) {
      const columns = [];
      const rows = [];
      // const metadataRecords = metadata.hits.hits
      columns.push({
        name: 'currently open',
        options: {
          display: true,
          viewColumns: true,
          filter: true,
        },
      });
      /* for (let recordIndex = 0; recordIndex < metadataRecords.length; recordIndex++) {
                const row = []
                const displayedFields = metadataRecords[recordIndex]._source.resource
                const flyoutCell = recordFlyout(metadataRecords[recordIndex]._source, recordIndex) */
      for (let recordIndex = 0; recordIndex < metadata.length; recordIndex++) {
        const row = [];
        const displayedFields = metadata[recordIndex].resource;
        const flyoutCell = recordFlyout(metadata[recordIndex], recordIndex);
        if (recordIndex >= isFlyoutOpen.length) {
          setIsFlyoutOpen([...isFlyoutOpen, false]);
        }
        row.push(flyoutCell);
        for (const fieldName in displayedFields) {
          if (typeof displayedFields[fieldName] === 'string') {
            if (recordIndex === 0) {
              const column = {
                name: fieldName,
                options: {
                  display: true,
                },
              };
              columns.push(column);
            }
            row.push(displayedFields[fieldName]);
          }
        }
        rows.push(row);
      }
      setResultsCol(columns);
      setResults(rows);
    }
  };

  return (
    <>
      <EuiSpacer size="s" />
      <EuiFlexGroup justifyContent="spaceAround">
        <EuiSpacer size="s" />
        <EuiFlexItem grow={false}>
          <EuiTitle size="xs">
            <h2>Your query : {searchQuery}</h2>
          </EuiTitle>
        </EuiFlexItem>
        <EuiSpacer size="s" />
      </EuiFlexGroup>
      <EuiFlexGroup>
        <EuiFlexItem>
          <EuiCallOut
            size="s"
            title="Click on a line of the table to inspect resource metadata (except for the first column)."
            iconType="search"
          />
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiButton
            fill
            onClick={() => {
              if (searchResults) {
                download(
                  `{"metadataRecords": ${JSON.stringify(searchResults, null, '\t')}}`,
                  'InSylvaSearchResults.json',
                  'application/json'
                );
              }
            }}
          >
            Download as JSON
          </EuiButton>
        </EuiFlexItem>
      </EuiFlexGroup>
      <MuiThemeProvider theme={getMuiTheme()}>
        <MUIDataTable
          title={'Search results'}
          data={results}
          columns={resultsCol}
          options={resultsGridOptions}
        />
      </MuiThemeProvider>
    </>
  );
};

export default Results;
