import React, { useState, useEffect } from 'react';
import { Map, View } from 'ol';
// import TileLayer from "ol/layer/Tile";
import { Tile as TileLayer, Vector as VectorLayer } from 'ol/layer';
import ImageLayer from 'ol/layer/Image';
import SourceOSM from 'ol/source/OSM';
import BingMaps from 'ol/source/BingMaps';
import { Vector as VectorSource } from 'ol/source';
import WMTS from 'ol/source/WMTS';
import WMTSTileGrid from 'ol/tilegrid/WMTS';
//import {fromLonLat, get as getProjection} from 'ol/proj.js';
import { getWidth } from 'ol/extent';
import ImageWMS from 'ol/source/ImageWMS';
import GeoJSON from 'ol/format/GeoJSON';
//import { Circle as CircleStyle, Fill, Stroke, Style, Text, Icon } from 'ol/style';
import { Fill, Stroke, Style, Text, Icon } from 'ol/style';
import { Circle, Point, Polygon } from 'ol/geom';
import Feature from 'ol/Feature';
import * as proj from 'ol/proj';
import {
  ScaleLine,
  MousePosition,
  OverviewMap,
  defaults as defaultControls,
} from 'ol/control';
import { toStringXY } from 'ol/coordinate';
import 'ol/ol.css';
import { EuiCheckbox } from '@elastic/eui';
import { htmlIdGenerator } from '@elastic/eui/lib/services';
import { updateArrayElement } from '../../Utils.js';

const SearchMap = (props) => {
  /*var image = new CircleStyle({
        radius: 5,
        fill: null,
        stroke: new Stroke({ color: 'red', width: 1 }),
    });*/
  const styles = {
    Point: new Style({
      image: new Icon({
        anchor: [0.5, 46],
        anchorXUnits: 'fraction',
        anchorYUnits: 'pixels',
        src: 'https://openlayers.org/en/v3.20.1/examples/data/icon.png',
      }),
    }),
    /* 'Circle': new Style({
            image: new Circle({
                radius: 7,
                fill: new Fill({
                    color: 'green'
                }),
                stroke: new Stroke({
                    color: 'blue',
                    width: 2
                })
            })
        }),*/
    Circle: new Style({
      stroke: new Stroke({
        color: 'blue',
        width: 2,
      }),
      //radius: 1000,
      fill: new Fill({
        color: 'rgba(0,0,255,0.3)',
      }),
    }),
    /* 'LineString': new Style({
            stroke: new Stroke({
                color: 'green',
                width: 1,
            }),
        }),
        'MultiLineString': new Style({
            stroke: new Stroke({
                color: 'green',
                width: 1,
            }),
        }),
        'MultiPoint': new Style({
            image: image,
        }),
        'MultiPolygon': new Style({
            stroke: new Stroke({
                color: 'yellow',
                width: 1,
            }),
            fill: new Fill({
                color: 'rgba(255, 255, 0, 0.1)',
            }),
        }),
        'Polygon': new Style({
            stroke: new Stroke({
                color: 'blue',
                lineDash: [4],
                width: 3,
            }),
            fill: new Fill({
                color: 'rgba(0, 0, 255, 0.1)',
            }),
        }),
        'GeometryCollection': new Style({
            stroke: new Stroke({
                color: 'magenta',
                width: 2,
            }),
            fill: new Fill({
                color: 'magenta',
            }),
            image: new CircleStyle({
                radius: 10,
                fill: null,
                stroke: new Stroke({
                    color: 'magenta',
                }),
            }),
        }),
        'Circle': new Style({
            stroke: new Stroke({
                color: 'red',
                width: 2,
            }),
            fill: new Fill({
                color: 'rgba(255,0,0,0.2)',
            }),
        }),
        bluecircle: {
            width: 30,
            height: 30,
            border: "1px solid #088",
            bordeRadius: "15",
            backgroundColor: "#0ff",
            opacity: 0.5,
            zIndex: 9999
        }, */
    mapContainer: {
      height: '80vh',
      width: '60vw',
    },
    layerTree: {
      cursor: 'pointer',
    },
  };
  const source = new SourceOSM();
  const overviewMapControl = new OverviewMap({
    layers: [
      new TileLayer({
        source: source,
      }),
    ],
  });
  const [center, setCenter] = useState(proj.fromLonLat([2.5, 46.5]));
  const [zoom, setZoom] = useState(6);
  const styleFunction = function (feature) {
    return styles[feature.getGeometry().getType()];
  };

  /* const newPoint = {
        'type': 'Feature',
        'geometry': {
            'type': 'Point',
            'coordinates': proj.fromLonLat([6.37777777778, 43.1938888889]),
        },
        'properties': {
            'Site': 'TEST'
        }
    }

    const geojsonObject = {
        'type': 'FeatureCollection',
        'crs': {
            'type': 'name',
            'properties': {
                'name': 'EPSG:4326',
            },
        },
        'features': [
            // newPoint
        ],
    } */

  const vectorSource = new VectorSource({
    /*features: new GeoJSON().readFeatures(geojsonObject),*/
    projection: 'EPSG:4326',
  });

  // vectorSource.addFeature(new Feature(new Polygon([[proj.fromLonLat([0, 45]), proj.fromLonLat([0, 50]), proj.fromLonLat([5, 50]), proj.fromLonLat([5, 45])]])));

  const vectorLayer = new VectorLayer({
    name: 'query_results',
    source: vectorSource,
    style: styleFunction,
  });

  const resolutions = [];
  const matrixIds = [];
  const proj3857 = proj.get('EPSG:3857');
  const maxResolution = getWidth(proj3857.getExtent()) / 256;

  for (let i = 0; i < 20; i++) {
    matrixIds[i] = i.toString();
    resolutions[i] = maxResolution / Math.pow(2, i);
  }

  const tileGrid = new WMTSTileGrid({
    origin: [-20037508, 20037508],
    resolutions: resolutions,
    matrixIds: matrixIds,
  });

  const [mapLayers, setMapLayers] = useState([
    new TileLayer({
      name: 'osm-layer',
      source: source,
    }),
    /* Bing Aerial */
    new TileLayer({
      name: 'Bing Aerial',
      preload: Infinity,
      source: new BingMaps({
        key: 'AtdZQap9X-lowJjvdPhTgr1BctJuGGm-ZoVw9wO6dHt1VDURjRKEkssetwOe31Xt',
        imagerySet: 'Aerial',
      }),
      //visible: false
    }),
    new TileLayer({
      name: 'IGN',
      source: new WMTS({
        url: 'https://wxs.ign.fr/choisirgeoportail/geoportail/wmts',
        layer: 'GEOGRAPHICALGRIDSYSTEMS.PLANIGNV2',
        matrixSet: 'PM',
        format: 'image/png',
        projection: 'EPSG:3857',
        tileGrid: tileGrid,
        style: 'normal',
        attributions:
          '<a href="https://www.ign.fr/" target="_blank">' +
          '<img src="https://wxs.ign.fr/static/logos/IGN/IGN.gif" title="Institut national de l\'' +
          'information géographique et forestière" alt="IGN"></a>',
      }),
    }),
    vectorLayer,

    // new ImageLayer({
    //     name: 'donuts-insylva-layer',
    //     source: new ImageWMS({
    //         url: 'http: //w3.avignon.inra.fr/geoserver/wms',
    //         params: { 'LAYERS': 'urfm:donut_view' },
    //         ratio: 1
    //     })
    // })
  ]);

  const [mapLayersVisibility, setMapLayersVisibility] = useState(
    new Array(mapLayers.length).fill(true)
  );

  // const posGreenwich = proj.fromLonLat([0, 51.47]);
  // set initial map objects
  const view = new View({
    center: center,
    zoom: zoom,
  });

  const [map] = useState(
    new Map({
      target: null,
      layers: mapLayers,
      controls: defaultControls().extend([
        new MousePosition({
          projection: 'EPSG:4326',
        }),
        new ScaleLine(),
        overviewMapControl,
      ]),
      view: view,
    })
  );

  const processData = (props) => {
    if (props.searchResults) {
      props.searchResults.forEach((result) => {
        if (
          result.experimental_site.geo_point &&
          result.experimental_site.geo_point.longitude &&
          result.experimental_site.geo_point.latitude
        ) {
          //vectorSource.addFeature(new Feature(new Point(proj.fromLonLat([result.experimental_site.geo_point.longitude, result.experimental_site.geo_point.latitude]))))
          //vectorSource.addFeature(new Feature(new Circle(toStringXY([result.experimental_site.geo_point.longitude, result.experimental_site.geo_point.latitude],1),10)))
          const coord = [
            result.experimental_site.geo_point.longitude,
            result.experimental_site.geo_point.latitude,
          ];
          vectorSource.addFeature(new Feature(new Circle(proj.fromLonLat(coord), 1000)));
          //vectorSource.addFeature(new Feature(new Circle([result.experimental_site.geo_point.longitude, result.experimental_site.geo_point.latitude],10)))
        }
      });
    }
  };

  // useEffect Hooks
  // [] = component did mount
  // set the initial map targets
  useEffect(() => {
    map.setTarget('map');
    map.on('moveend', () => {
      setCenter(map.getView().getCenter());
      setZoom(map.getView().getZoom());
    });

    /* map.getCurrentScale = function () {
            //var map = this.getMap();
            var map = this;
            var view = map.getView();
            var resolution = view.getResolution();
            var units = map.getView().getProjection().getUnits();
            var dpi = 25.4 / 0.28;
            var mpu = proj.METERS_PER_UNIT[units];
            var scale = resolution * mpu * 39.37 * dpi;
            return scale;

        };
        map.getView().on('change:resolution', function(evt){

        var divScale = 10;// to adjusting
        var radius =  map.getCurrentScale()/divScale;
        Circle.getStyle().getGeometry().setRadius(radius);
        }); */

    // Basic overlay to show where i want the new center to be
    /* const overlay = new Overlay({
            position: posGreenwich,
            element: overlayRef.current,
            positioning: "center-center",
            stopEvent: false
        });
        map.addOverlay(overlay); */
    map.getView().animate({ zoom: zoom }, { center: center }, { duration: 2000 });

    processData(props);
    // clean up upon component unmount
    /* return () => {
            map.setTarget(null);
        }; */
  }, [props]);

  const getLayerIndex = (name) => {
    let index = 0;
    mapLayers.forEach((layer) => {
      if (layer.get('name') === name) {
        index = mapLayers.indexOf(layer);
      }
    });
    return index;
  };

  const toggleLayer = (name) => {
    let updatedLayers = mapLayers;
    const layerIndex = getLayerIndex(name);
    // let updatedLayer = updatedLayers[getLayerIndex(name)]
    setMapLayersVisibility(
      updateArrayElement(
        mapLayersVisibility,
        layerIndex,
        !mapLayersVisibility[layerIndex]
      )
    );
    updatedLayers[layerIndex].setVisible(!updatedLayers[layerIndex].getVisible());
    setMapLayers(updatedLayers);
  };

  // helpers
  /* const btnAction = () => {
        // when button is clicked, recentre map
        // this does not work :(
        setCenter(posGreenwich);
        setZoom(6);
    }; */
  // render
  return (
    <div>
      <div id="map" style={styles.mapContainer}></div>
      <div id="layertree">
        <br />
        <h5>Cliquez sur les couches pour modifier leur visibilité.</h5>
        <br />
        <table>
          <thead>
            <tr>
              <th>Fonds carto ou vecteur</th>
              {/*<th align="center">Couches INSYLVA</th>
    <th>Infos attributs</th>*/}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <ul>
                  <li>
                    <EuiCheckbox
                      id={htmlIdGenerator()()}
                      label="Query result"
                      checked={mapLayersVisibility[getLayerIndex('query_results')]}
                      onChange={(e) => toggleLayer('query_results')}
                    />
                  </li>
                  <li>
                    <EuiCheckbox
                      id={htmlIdGenerator()()}
                      label="Open Street Map"
                      checked={mapLayersVisibility[getLayerIndex('osm-layer')]}
                      onChange={(e) => toggleLayer('osm-layer')}
                    />
                  </li>
                  <li>
                    <EuiCheckbox
                      id={htmlIdGenerator()()}
                      label="Bing Aerial"
                      checked={mapLayersVisibility[getLayerIndex('Bing Aerial')]}
                      onChange={(e) => toggleLayer('Bing Aerial')}
                    />
                  </li>
                  <li>
                    <EuiCheckbox
                      id={htmlIdGenerator()()}
                      label="PLAN IGN"
                      checked={mapLayersVisibility[getLayerIndex('IGN')]}
                      onChange={(e) => toggleLayer('IGN')}
                    />
                  </li>
                  {/*<li>
                                    <EuiCheckbox
                                        id={htmlIdGenerator()()}
                                        label="Départements"
                                        checked={mapLayersVisibility[getLayerIndex("dept-layer")]}
                                        onChange={e => toggleLayer("dept-layer")}
                                    />
                                </li>
                                <li>
                                    <EuiCheckbox
                                        id={htmlIdGenerator()()}
                                        label="Régions"
                                        checked={mapLayersVisibility[getLayerIndex("regs-layer")]}
                                        onChange={e => toggleLayer("regs-layer")}
                                    />
                                </li>*/}
                </ul>
              </td>
              <td>
                <div id="info">&nbsp;</div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      {/*<div
                style={styles.bluecircle}
                ref={overlayRef}
                id="overlay"
                title="overlay"
            />*/}
      {/*<button
                style={{
                    position: "absolute",
                    right: 10,
                    top: 10,
                    backgroundColor: "white"
                }}
                onClick={() => {
                    btnAction();
                }}
            >
                CLICK
            </button>*/}
    </div>
  );
};

export default SearchMap;
