import React from 'react';
import { EuiPage, EuiPageBody } from '@elastic/eui';
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom';
import Layout from './components/Layout';

const App = () => {
  return (
    <EuiPage restrictWidth={false}>
      <EuiPageBody>
        <HashRouter>
          <Switch>
            <Route exact path="/" render={() => <Redirect to="/app/home" />} />
            <Route component={Layout} />
            <Route component={Error} />
          </Switch>
        </HashRouter>
      </EuiPageBody>
    </EuiPage>
  );
};
export default App;
