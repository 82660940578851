import React from 'react';
import { InSylvaGatekeeperClient } from './InSylvaGatekeeperClient';
import { InSylvaKeycloakClient } from './InSylvaKeycloakClient';
import { getLoginUrl } from '../Utils';

const UserStateContext = React.createContext();
const UserDispatchContext = React.createContext();

const igClient = new InSylvaGatekeeperClient();
igClient.baseUrl = process.env.REACT_APP_IN_SYLVA_GATEKEEPER_PORT
  ? `${process.env.REACT_APP_IN_SYLVA_GATEKEEPER_HOST}:${process.env.REACT_APP_IN_SYLVA_GATEKEEPER_PORT}`
  : `${window._env_.REACT_APP_IN_SYLVA_GATEKEEPER_HOST}`;

const ikcClient = new InSylvaKeycloakClient();
ikcClient.baseUrl = process.env.REACT_APP_IN_SYLVA_KEYCLOAK_PORT
  ? `${process.env.REACT_APP_IN_SYLVA_KEYCLOAK_HOST}:${process.env.REACT_APP_IN_SYLVA_KEYCLOAK_PORT}`
  : `${window._env_.REACT_APP_IN_SYLVA_KEYCLOAK_HOST}`;
ikcClient.realm = process.env.REACT_APP_IN_SYLVA_KEYCLOAK_PORT
  ? `${process.env.REACT_APP_IN_SYLVA_REALM}`
  : `${window._env_.REACT_APP_IN_SYLVA_REALM}`;
ikcClient.client_id = process.env.REACT_APP_IN_SYLVA_KEYCLOAK_PORT
  ? `${process.env.REACT_APP_IN_SYLVA_CLIENT_ID}`
  : `${window._env_.REACT_APP_IN_SYLVA_CLIENT_ID}`;
ikcClient.grant_type = process.env.REACT_APP_IN_SYLVA_KEYCLOAK_PORT
  ? `${process.env.REACT_APP_IN_SYLVA_GRANT_TYPE}`
  : `${window._env_.REACT_APP_IN_SYLVA_GRANT_TYPE}`;

function userReducer(state, action) {
  switch (action.type) {
    case 'USER_LOGGED_IN':
      return { ...state, isAuthenticated: true };
    case 'SIGN_OUT_SUCCESS':
      return { ...state, isAuthenticated: false };
    case 'EXPIRED_SESSION':
      return { ...state, isAuthenticated: false };
    case 'USER_NOT_LOGGED_IN':
      return { ...state, isAuthenticated: false };
    case 'STD_FIELDS_SUCCESS':
      return { ...state, isAuthenticated: true };
    case 'STD_FIELDS_FAILURE':
      return { ...state, isAuthenticated: true };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function UserProvider({ children }) {
  const [state, dispatch] = React.useReducer(userReducer, {
    isAuthenticated: !!sessionStorage.getItem('access_token'),
  });

  return (
    <UserStateContext.Provider value={state}>
      <UserDispatchContext.Provider value={dispatch}>
        {children}
      </UserDispatchContext.Provider>
    </UserStateContext.Provider>
  );
}

function useUserState() {
  const context = React.useContext(UserStateContext);
  if (context === undefined) {
    throw new Error('useUserState must be used within a UserProvider');
  }
  return context;
}

function useUserDispatch() {
  const context = React.useContext(UserDispatchContext);
  if (context === undefined) {
    throw new Error('useUserDispatch must be used within a UserProvider');
  }
  return context;
}

async function checkUserLogin(userId, accessToken, refreshToken) {
  if (!!userId && !!accessToken && !!refreshToken) {
    sessionStorage.setItem('user_id', userId);
    sessionStorage.setItem('access_token', accessToken);
    sessionStorage.setItem('refresh_token', refreshToken);
    //To Do:
    // Load the user histories from UserHistory(userId) endpoint
    // Load the user result filters from Result_Filter(userId) endpoints
    // Load the user policies from Policy(userId) endpoint
    if (!sessionStorage.getItem('token_refresh_time')) {
      sessionStorage.setItem('token_refresh_time', Date.now());
    }
    // dispatch({ type: "USER_LOGGED_IN" });
  } else {
    // dispatch({ type: "USER_NOT_LOGGED_IN" });
  }
}

async function refreshToken() {
  if (!!sessionStorage.getItem('user_id')) {
    setTimeout(async () => {
      const result = await ikcClient.refreshToken({
        refresh_token: sessionStorage.getItem('refresh_token'),
      });
      if (result) {
        sessionStorage.setItem('access_token', result.token.access_token);
        sessionStorage.setItem('token_refresh_time', Date.now());
        // dispatch({ type: "USER_LOGGED_IN" });
      } else {
        // dispatch({ type: "LOGIN_FAILURE" });
      }
    }, 3000);
  } else {
    // dispatch({ type: "EXPIRED_SESSION" });
  }
}

async function signOut() {
  await ikcClient.logout({});
  sessionStorage.removeItem('user_id');
  sessionStorage.removeItem('access_token');
  sessionStorage.removeItem('refresh_token');
  // dispatch({ type: "SIGN_OUT_SUCCESS" });
  window.location.replace(getLoginUrl() + '?requestType=search');
}

export {
  UserProvider,
  useUserState,
  useUserDispatch,
  checkUserLogin,
  refreshToken,
  signOut,
};
