import React, { useState, useEffect } from 'react';
import {
  EuiProgress,
  EuiRadioGroup,
  EuiFieldText,
  EuiPanel,
  EuiPopover,
  EuiPopoverTitle,
  EuiPopoverFooter,
  EuiTabbedContent,
  EuiFormRow,
  EuiComboBox,
  EuiPageContentBody,
  EuiForm,
  EuiTextArea,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFieldSearch,
  EuiButton,
  EuiButtonEmpty,
  EuiSwitch,
  EuiButtonIcon,
  EuiIcon,
  EuiSpacer,
  EuiPageContent,
  EuiPageContentHeader,
  EuiTitle,
  EuiPageContentHeaderSection,
  EuiTextColor,
  EuiOverlayMask,
  EuiModal,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiModalBody,
  EuiModalFooter,
  EuiSelect,
  EuiGlobalToastList,
  EuiHealth,
} from '@elastic/eui';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { Operators, NumericOptions, DateOptions } from './Data';
import Results from '../results/Results';
import SearchMap from '../maps/SearchMap';
import {
  createBasicQueriesBySource,
  changeNameToLabel,
  SearchField,
  removeNullFields,
  getSections,
  getFieldsBySection,
  updateArrayElement,
  removeArrayElement,
  updateSearchFieldValues,
  createAdvancedQueriesBySource,
} from '../../Utils.js';
import {
  fetchPublicFields,
  fetchUserPolicyFields,
  fetchSources,
  searchQuery,
  getQueryCount,
} from '../../actions/source';
import { addUserHistory, fetchUserHistory } from '../../actions/user';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 240,
  },
}));

const fieldValuesToString = (field) => {
  let strValues = '';
  switch (field.type) {
    case 'Numeric':
      field.values.forEach((element) => {
        switch (element.option) {
          case 'between':
            strValues = `${strValues} ${element.value1} <= ${field.name} <= ${element.value2} or `;
            break;
          default:
            strValues = `${strValues} ${field.name} ${element.option} ${element.value1} or `;
        }
      });
      if (strValues.endsWith('or '))
        strValues = strValues.substring(0, strValues.length - 4);
      break;
    case 'Date':
      field.values.forEach((element) => {
        switch (element.option) {
          case 'between':
            strValues = `${strValues} ${element.startDate} <= ${field.name} <= ${element.endDate} or `;
            break;
          default:
            strValues = `${strValues} ${field.name} ${element.option} ${element.startDate} or `;
        }
      });
      if (strValues.endsWith(' or '))
        strValues = strValues.substring(0, strValues.length - 4);
      break;
    case 'List':
      strValues = `${strValues} ${field.name} = `;
      field.values.forEach((element) => {
        strValues = `${strValues} ${element.label}, `;
      });
      if (strValues.endsWith(', '))
        strValues = strValues.substring(0, strValues.length - 2);
      break;

    //type : text
    default:
      strValues = `${strValues} ${field.name} = ${field.values}`;
  }
  return strValues;
};

const updateSources = (
  searchFields,
  sources,
  setSelectedSources,
  setAvailableSources
) => {
  let updatedSources = [];
  let availableSources = [];
  let noPrivateField = true;
  //search for policy fields to filter sources
  searchFields.forEach((field) => {
    if (field.isValidated) {
      //if sources haven't already been filtered
      if (noPrivateField && !updatedSources.length) {
        availableSources = sources;
      } else {
        availableSources = updatedSources;
      }
      updatedSources = [];
      field.sources.forEach((sourceId) => {
        noPrivateField = false;
        const source = availableSources.find((src) => src.id === sourceId);
        if (source && !updatedSources.includes(source)) updatedSources.push(source);
      });
    }
  });
  setSelectedSources(updatedSources);
  if (noPrivateField && !updatedSources.length) {
    setAvailableSources(sources);
  } else {
    setAvailableSources(updatedSources);
  }
};

const fetchHistory = (setUserHistory) => {
  fetchUserHistory(sessionStorage.getItem('user_id')).then((result) => {
    if (result[0] && result[0].ui_structure) {
      result.forEach((item) => {
        item.ui_structure = JSON.parse(item.ui_structure);
        item.label = `${item.name} - ${new Date(item.createdat).toLocaleString()}`;
      });
    }
    setUserHistory(result);
  });
};

const addHistory = (
  kcID,
  search,
  searchName,
  searchFields,
  searchDescription,
  setUserHistory
) => {
  addUserHistory(
    sessionStorage.getItem('user_id'),
    search,
    searchName,
    searchFields,
    searchDescription
  ).then(() => {
    fetchHistory(setUserHistory);
  });
};

const updateSearch = (setSearch, searchFields, selectedOperatorId, setSearchCount) => {
  let searchText = '';
  searchFields.forEach((field) => {
    if (field.isValidated) {
      searchText =
        searchText +
        `{${fieldValuesToString(field)} } ${Operators[selectedOperatorId].value.toUpperCase()} `;
    }
  });
  if (searchText.endsWith(' AND ')) {
    searchText = searchText.substring(0, searchText.length - 5);
  } else if (searchText.endsWith(' OR ')) {
    searchText = searchText.substring(0, searchText.length - 4);
  }
  setSearchCount();
  setSearch(searchText);
};

const HistorySelect = (
  sources,
  setAvailableSources,
  setSelectedSources,
  setSearch,
  searchFields,
  selectedOperatorId,
  userHistory,
  setUserHistory,
  setSearchFields,
  setSearchCount,
  setFieldCount,
  selectedSavedSearch,
  setSelectedSavedSearch,
  historySelectError,
  setHistorySelectError
) => {
  if (Object.keys(userHistory).length !== 0) {
    const onHistoryChange = (selectedSavedSearch) => {
      setHistorySelectError(undefined);
      if (!!selectedSavedSearch[0].query) {
        setSelectedSavedSearch(selectedSavedSearch);
        setSearch(selectedSavedSearch[0].query);
        setSearchCount();
        setFieldCount([]);
      }
      if (!!selectedSavedSearch[0].ui_structure) {
        updateSources(
          selectedSavedSearch[0].ui_structure,
          sources,
          setSelectedSources,
          setAvailableSources
        );
        setSearchFields(selectedSavedSearch[0].ui_structure);
      }
    };

    const onHistorySearchChange = (value, hasMatchingOptions) => {
      setHistorySelectError(
        value.length === 0 || hasMatchingOptions
          ? undefined
          : `"${value}" is not a valid option`
      );
    };

    return (
      <>
        <EuiFormRow
          error={historySelectError}
          isInvalid={historySelectError !== undefined}
        >
          <EuiComboBox
            placeholder="Load a previous search"
            singleSelection={{ asPlainText: true }}
            options={userHistory}
            selectedOptions={selectedSavedSearch}
            onChange={onHistoryChange}
            onSearchChange={onHistorySearchChange}
          />
        </EuiFormRow>
      </>
    );
  }
};

const SearchBar = (
  isLoading,
  setIsLoading,
  search,
  setSearch,
  searchResults,
  setSearchResults,
  searchFields,
  setSearchFields,
  searchName,
  setSearchName,
  searchDescription,
  setSearchDescription,
  readOnlyQuery,
  setReadOnlyQuery,
  selectedSources,
  setSelectedSources,
  availableSources,
  setAvailableSources,
  standardFields,
  sources,
  setSelectedTabNumber,
  searchCount,
  setSearchCount,
  setFieldCount,
  isReadOnlyModalOpen,
  setIsReadOnlyModalOpen,
  isSaveSearchModalOpen,
  setIsSaveSearchModalOpen,
  userHistory,
  setUserHistory,
  selectedSavedSearch,
  setSelectedSavedSearch,
  historySelectError,
  setHistorySelectError,
  selectedOperatorId,
  createEditableQueryToast
) => {
  // const closeReadOnlyModal = () => setIsReadOnlyModalOpen(false)

  /* const switchReadOnly = (readOnlyQuery, isReadOnlyModalOpen) => {
        if (readOnlyQuery) {
            setIsReadOnlyModalOpen(true)
        } else {
            setReadOnlyQuery(true)
        } */
  /* if (!localStorage.getItem("InSylvaReadOnlySearch") && readOnlyQuery) {
        setIsReadOnlyModalOpen(!isReadOnlyModalOpen)
    } */
  // }

  /* let readOnlyModal;

    if (isReadOnlyModalOpen) {
        readOnlyModal = (
            <EuiOverlayMask>
                <EuiConfirmModal
                    title="Allow query editing"
                    onCancel={() => closeReadOnlyModal()}
                    onConfirm={() => {
                        setReadOnlyQuery(!readOnlyQuery)
                        closeReadOnlyModal()
                    }}
                    cancelButtonText="No"
                    confirmButtonText="Yes"
                    buttonColor="danger"
                    defaultFocusedButton="confirm">
                    <p>Be aware that manually editing the query can spoil search results.</p>
                    <p>The syntax needs to be respected :</p>
                    <ul>Fields and their values must be given between brackets : &#123; &#125;</ul>
                    <ul>Check eventual typing mistakes</ul>
                    <ul>Make sure every opened bracket is properly closed</ul>
                    <p>Are you sure you want to do this?</p>
                </EuiConfirmModal>
            </EuiOverlayMask>
        )
    }*/

  const closeSaveSearchModal = () => setIsSaveSearchModalOpen(false);

  let saveSearchModal;

  if (isSaveSearchModalOpen) {
    saveSearchModal = (
      <EuiOverlayMask>
        <EuiModal onClose={closeSaveSearchModal} initialFocus="[name=searchName]">
          <EuiModalHeader>
            <EuiModalHeaderTitle>Save search</EuiModalHeaderTitle>
          </EuiModalHeader>

          <EuiModalBody>
            <EuiForm>
              <EuiFormRow label="Search name">
                <EuiFieldText
                  name="searchName"
                  value={searchName}
                  onChange={(e) => {
                    setSearchName(e.target.value);
                  }}
                />
              </EuiFormRow>
              <EuiFormRow label="Description (optional)">
                <EuiTextArea
                  value={searchDescription}
                  onChange={(e) => setSearchDescription(e.target.value)}
                  placeholder="Search description..."
                  fullWidth
                  compressed
                />
              </EuiFormRow>
            </EuiForm>
          </EuiModalBody>

          <EuiModalFooter>
            <EuiButtonEmpty
              onClick={() => {
                closeSaveSearchModal();
              }}
            >
              Cancel
            </EuiButtonEmpty>
            <EuiButton
              onClick={() => {
                if (!!searchName) {
                  addHistory(
                    sessionStorage.getItem('user_id'),
                    search,
                    searchName,
                    searchFields,
                    searchDescription,
                    setUserHistory
                  );
                  setSearchName('');
                  setSearchDescription('');
                  closeSaveSearchModal();
                }
              }}
              fill
            >
              Save
            </EuiButton>
          </EuiModalFooter>
        </EuiModal>
      </EuiOverlayMask>
    );
  }

  return (
    <>
      {/*!readOnlyQuery ?
                <>
                    <EuiCallOut title="Proceed with caution!" color="warning" iconType="alert">
                        <p>Be aware that manually editing the query can spoil search results. The syntax must be respected :</p>
                        <ul>Fields and their values should be put between brackets : &#123; &#125;  -  Make sure every opened bracket is properly closed</ul>
                        <ul>"AND" and "OR" should be capitalized between different fields conditions and lowercased within a field expression</ul>
                        <ul>Make sure to check eventual typing mistakes</ul>

                    </EuiCallOut>
                    <EuiSpacer size="s" />
                </>
                : <></>
            */}
      <EuiFlexGroup>
        <EuiFlexItem>
          <EuiTextArea
            readOnly={readOnlyQuery}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            placeholder="Add fields..."
            fullWidth
          />
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiButton
            size="s"
            fill
            onClick={() => {
              if (search.trim()) {
                setIsLoading(true);
                const queriesWithIndices = createAdvancedQueriesBySource(
                  standardFields,
                  search,
                  selectedSources,
                  availableSources
                );
                searchQuery(queriesWithIndices).then((result) => {
                  // sessionStorage.setItem("searchResults", JSON.stringify(result))
                  setSearchResults(result);
                  setSelectedTabNumber(1);
                  setIsLoading(false);
                });
              }
            }}
          >
            Search
          </EuiButton>
          <EuiSpacer size="s" />
          {isNaN(searchCount) ? (
            <></>
          ) : (
            <>
              <EuiTextColor
                color="secondary"
                style={{ display: 'flex', justifyContent: 'center' }}
              >
                {searchCount} {searchCount === 1 ? 'result' : 'results'}
              </EuiTextColor>
              <EuiSpacer size="s" />
            </>
          )}
          <EuiButton
            size="s"
            onClick={() => {
              if (!!search) {
                const queriesWithIndices = createAdvancedQueriesBySource(
                  standardFields,
                  search,
                  selectedSources,
                  availableSources
                );
                getQueryCount(queriesWithIndices).then((result) => {
                  if (result || result === 0) setSearchCount(result);
                });
              }
            }}
          >
            Count results
          </EuiButton>
          <EuiSpacer size="s" />
          <EuiButton
            size="s"
            onClick={() => {
              setIsSaveSearchModalOpen(true);
            }}
          >
            Save search
          </EuiButton>
          {saveSearchModal}
          <EuiSpacer size="s" />
          <EuiSwitch
            compressed
            label={'Editable'}
            checked={!readOnlyQuery}
            onChange={() => {
              // switchReadOnly(readOnlyQuery, isReadOnlyModalOpen)
              setReadOnlyQuery(!readOnlyQuery);
              if (readOnlyQuery) {
                createEditableQueryToast();
              }
            }}
          />
          {/* readOnlyModal */}
        </EuiFlexItem>
      </EuiFlexGroup>
      {isLoading && (
        <EuiFlexGroup>
          <EuiFlexItem>
            <EuiProgress postion="fixed" size="l" color="accent" />
          </EuiFlexItem>
        </EuiFlexGroup>
      )}
      <EuiSpacer size="s" />
      <EuiFlexGroup>
        <EuiFlexItem>
          {HistorySelect(
            sources,
            setAvailableSources,
            setSelectedSources,
            setSearch,
            searchFields,
            selectedOperatorId,
            userHistory,
            setUserHistory,
            setSearchFields,
            setSearchCount,
            setFieldCount,
            selectedSavedSearch,
            setSelectedSavedSearch,
            historySelectError,
            setHistorySelectError
          )}
        </EuiFlexItem>
      </EuiFlexGroup>
    </>
  );
};

const PopoverSelect = (
  standardFields,
  setStandardFields,
  searchFields,
  setSearchFields,
  selectedField,
  setSelectedField,
  selectedSection,
  setSelectedSection,
  isPopoverSelectOpen,
  setIsPopoverSelectOpen,
  fieldCount,
  setFieldCount,
  selectedSources,
  setSelectedSources
) => {
  const handleAddfield = () => {
    if (!!selectedField[0]) {
      const field = standardFields.find(
        (item) =>
          item.field_name.replace(/_|\./g, ' ') ===
          selectedSection[0].label + ' ' + selectedField[0].label
      );
      switch (field.field_type) {
        case 'Text':
          setSearchFields([
            ...searchFields,
            new SearchField(field.field_name, field.field_type, '', false, field.sources),
          ]);
          break;
        case 'List':
          setSearchFields([
            ...searchFields,
            new SearchField(field.field_name, field.field_type, [], false, field.sources),
          ]);
          break;
        default:
          setSearchFields([
            ...searchFields,
            new SearchField(
              field.field_name,
              field.field_type,
              [{}],
              false,
              field.sources
            ),
          ]);
      }
    }
  };

  const selectField = () => {
    const renderOption = (option, searchValue, contentClassName) => {
      const { label, color } = option;
      return <EuiHealth color={color}>{label}</EuiHealth>;
    };
    if (selectedSection.length) {
      return (
        <>
          <EuiComboBox
            placeholder="Select a field"
            singleSelection={{ asPlainText: true }}
            options={getFieldsBySection(standardFields, selectedSection[0])}
            selectedOptions={selectedField}
            onChange={(selected) => setSelectedField(selected)}
            isClearable={true}
            renderOption={renderOption}
          />
          <EuiPopoverFooter>
            <EuiButton
              size="s"
              onClick={() => {
                handleAddfield();
                setIsPopoverSelectOpen(false);
                setSelectedSection([]);
                setSelectedField([]);
              }}
            >
              Add this field
            </EuiButton>
          </EuiPopoverFooter>
        </>
      );
    }
  };

  return (
    <EuiPopover
      panelPaddingSize="s"
      button={
        <EuiButton
          iconType="listAdd"
          iconSide="left"
          onClick={() => setIsPopoverSelectOpen(!isPopoverSelectOpen)}
        >
          Add field
        </EuiButton>
      }
      isOpen={isPopoverSelectOpen}
      closePopover={() => setIsPopoverSelectOpen(false)}
    >
      <div style={{ width: 'intrinsic', minWidth: 240 }}>
        <EuiPopoverTitle>Select a field</EuiPopoverTitle>
        <EuiComboBox
          placeholder="Select a section"
          singleSelection={{ asPlainText: true }}
          options={getSections(standardFields)}
          selectedOptions={selectedSection}
          onChange={(selected) => {
            setSelectedSection(selected);
            setSelectedField([]);
          }}
          isClearable={false}
        />
      </div>
      {selectField()}
    </EuiPopover>
  );
};

const PopoverValueContent = (
  index,
  standardFields,
  setStandardFields,
  searchFields,
  setSearchFields,
  valueError,
  setValueError,
  search,
  setSearch,
  setSearchCount,
  fieldCount,
  setFieldCount,
  isPopoverValueOpen,
  setIsPopoverValueOpen,
  selectedOperatorId,
  datePickerStyles,
  createPolicyToast,
  selectedSources,
  setSelectedSources,
  availableSources,
  setAvailableSources
) => {
  const onValueSearchChange = (value, hasMatchingOptions) => {
    setValueError(
      value.length === 0 || hasMatchingOptions
        ? undefined
        : `"${value}" is not a valid option`
    );
  };

  const validateFieldValues = () => {
    let fieldValues;
    if (Array.isArray(searchFields[index].values)) {
      fieldValues = [];
      searchFields[index].values.forEach((value) => {
        if (!!value) {
          fieldValues.push(value);
        }
      });
    } else {
      fieldValues = searchFields[index].values;
    }

    const updatedSearchFields = updateArrayElement(
      searchFields,
      index,
      new SearchField(
        searchFields[index].name,
        searchFields[index].type,
        fieldValues,
        true,
        searchFields[index].sources
      )
    );
    setSearchFields(updatedSearchFields);
    updateSearch(setSearch, updatedSearchFields, selectedOperatorId, setSearchCount);
    setFieldCount(updateArrayElement(fieldCount, index));
    if (searchFields[index].sources.length) {
      const filteredSources = [];
      searchFields[index].sources.forEach((sourceId) => {
        let source;
        if (selectedSources.length) {
          source = selectedSources.find((src) => src.id === sourceId);
        } else {
          source = availableSources.find((src) => src.id === sourceId);
        }
        if (source) {
          filteredSources.push(source);
        }
      });
      setAvailableSources(filteredSources);
      setSelectedSources(filteredSources);
      createPolicyToast();
    }
  };

  const invalidateFieldValues = () => {
    const updatedSearchFields = updateArrayElement(
      searchFields,
      index,
      new SearchField(
        searchFields[index].name,
        searchFields[index].type,
        searchFields[index].values,
        false,
        searchFields[index].sources
      )
    );
    setSearchFields(updatedSearchFields);
    updateSearch(setSearch, updatedSearchFields, selectedOperatorId, setSearchCount);
  };

  const ValuePopoverFooter = (i) => {
    if (i === searchFields[index].values.length - 1) {
      return (
        <EuiPopoverFooter>
          <EuiButton
            size="s"
            onClick={() => {
              setSearchFields(
                updateArrayElement(
                  searchFields,
                  index,
                  new SearchField(
                    searchFields[index].name,
                    searchFields[index].type,
                    [...searchFields[index].values, {}],
                    false,
                    searchFields[index].sources
                  )
                )
              );
            }}
          >
            Add value
          </EuiButton>
          <EuiButton
            size="s"
            style={{ float: 'right' }}
            onClick={() => {
              validateFieldValues();
              setIsPopoverValueOpen(updateArrayElement(isPopoverValueOpen, index, false));
            }}
          >
            Validate
          </EuiButton>
        </EuiPopoverFooter>
      );
    }
  };

  const addFieldValue = (i, selectedOption) => {
    setSearchFields(
      updateSearchFieldValues(
        searchFields,
        index,
        updateArrayElement(searchFields[index].values, i, { option: selectedOption })
      )
    );
  };

  const getListFieldValues = () => {
    const listFieldValues = [];
    standardFields
      .find((item) => item.field_name === searchFields[index].name)
      .values.split(', ')
      .sort()
      .forEach((element) => {
        listFieldValues.push({ label: element });
      });
    return listFieldValues;
  };

  switch (searchFields[index].type) {
    case 'Text':
      return (
        <>
          <EuiFlexItem>
            <EuiFieldText
              placeholder={'Type values'}
              value={searchFields[index].values}
              onChange={(e) =>
                setSearchFields(
                  updateSearchFieldValues(searchFields, index, e.target.value)
                )
              }
            />
          </EuiFlexItem>
          <EuiPopoverFooter>
            <EuiButton
              size="s"
              style={{ float: 'right' }}
              onClick={() => {
                validateFieldValues();
                setIsPopoverValueOpen(
                  updateArrayElement(isPopoverValueOpen, index, false)
                );
              }}
            >
              Validate
            </EuiButton>
          </EuiPopoverFooter>
        </>
      );
    case 'List':
      return (
        <>
          <EuiFormRow error={valueError} isInvalid={valueError !== undefined}>
            <EuiComboBox
              placeholder={'Select values'}
              options={getListFieldValues()}
              selectedOptions={searchFields[index].values}
              onChange={(selectedOptions) => {
                setValueError(undefined);
                setSearchFields(
                  updateSearchFieldValues(searchFields, index, selectedOptions)
                );
              }}
              onSearchChange={onValueSearchChange}
            />
          </EuiFormRow>
          <EuiPopoverFooter>
            <EuiButton
              size="s"
              style={{ float: 'right' }}
              onClick={() => {
                validateFieldValues();
                setIsPopoverValueOpen(
                  updateArrayElement(isPopoverValueOpen, index, false)
                );
              }}
            >
              Validate
            </EuiButton>
          </EuiPopoverFooter>
        </>
      );

    case 'Numeric':
      const NumericValues = (i) => {
        if (!!searchFields[index].values[i].option) {
          switch (searchFields[index].values[i].option) {
            case 'between':
              return (
                <>
                  <EuiFlexItem>
                    <EuiFieldText
                      placeholder={'1st value'}
                      value={searchFields[index].values[i].value1}
                      onChange={(e) => {
                        setSearchFields(
                          updateSearchFieldValues(
                            searchFields,
                            index,
                            updateArrayElement(searchFields[index].values, i, {
                              option: searchFields[index].values[i].option,
                              value1: e.target.value,
                              value2: searchFields[index].values[i].value2,
                            })
                          )
                        );
                      }}
                    />
                  </EuiFlexItem>
                  <EuiFlexItem>
                    <EuiFieldText
                      placeholder={'2nd value'}
                      value={searchFields[index].values[i].value2}
                      onChange={(e) =>
                        setSearchFields(
                          updateSearchFieldValues(
                            searchFields,
                            index,
                            updateArrayElement(searchFields[index].values, i, {
                              option: searchFields[index].values[i].option,
                              value1: searchFields[index].values[i].value1,
                              value2: e.target.value,
                            })
                          )
                        )
                      }
                    />
                  </EuiFlexItem>
                  {ValuePopoverFooter(i)}
                </>
              );

            default:
              return (
                <>
                  <EuiFlexItem>
                    <EuiFieldText
                      placeholder={'Type value'}
                      value={searchFields[index].values[i].value1}
                      onChange={(e) => {
                        setSearchFields(
                          updateSearchFieldValues(
                            searchFields,
                            index,
                            updateArrayElement(searchFields[index].values, i, {
                              option: searchFields[index].values[i].option,
                              value1: e.target.value,
                              value2: searchFields[index].values[i].value2,
                            })
                          )
                        );
                      }}
                    />
                  </EuiFlexItem>
                  {ValuePopoverFooter(i)}
                </>
              );
          }
        }
      };

      return (
        <>
          {searchFields[index].values.map((value, i) => (
            <div key={i}>
              <EuiSelect
                hasNoInitialSelection
                id="Select an option"
                options={NumericOptions}
                value={searchFields[index].values[i].option}
                onChange={(e) => {
                  addFieldValue(i, e.target.value);
                  invalidateFieldValues();
                }}
              />
              {NumericValues(i)}
            </div>
          ))}
        </>
      );

    case 'Date':
      const SelectDates = (i) => {
        if (!!searchFields[index].values[i].option) {
          switch (searchFields[index].values[i].option) {
            case 'between':
              return (
                <>
                  <form className={datePickerStyles.container} noValidate>
                    <TextField
                      label="between"
                      type="date"
                      defaultValue={
                        !!searchFields[index].values[i].startDate
                          ? searchFields[index].values[i].startDate
                          : Date.now()
                      }
                      className={datePickerStyles.textField}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(e) =>
                        setSearchFields(
                          updateSearchFieldValues(
                            searchFields,
                            index,
                            updateArrayElement(searchFields[index].values, i, {
                              option: searchFields[index].values[i].option,
                              startDate: e.target.value,
                              endDate: searchFields[index].values[i].endDate,
                            })
                          )
                        )
                      }
                    />
                  </form>
                  <form className={datePickerStyles.container} noValidate>
                    <TextField
                      label="and"
                      type="date"
                      defaultValue={
                        !!searchFields[index].values[i].endDate
                          ? searchFields[index].values[i].endDate
                          : Date.now()
                      }
                      className={datePickerStyles.textField}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(e) =>
                        setSearchFields(
                          updateSearchFieldValues(
                            searchFields,
                            index,
                            updateArrayElement(searchFields[index].values, i, {
                              option: searchFields[index].values[i].option,
                              startDate: searchFields[index].values[i].startDate,
                              endDate: e.target.value,
                            })
                          )
                        )
                      }
                    />
                  </form>
                  {ValuePopoverFooter(i)}
                </>
              );

            default:
              return (
                <>
                  <form className={datePickerStyles.container} noValidate>
                    <TextField
                      type="date"
                      defaultValue={
                        !!searchFields[index].values[i].startDate
                          ? searchFields[index].values[i].startDate
                          : Date.now()
                      }
                      className={datePickerStyles.textField}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(e) =>
                        setSearchFields(
                          updateSearchFieldValues(
                            searchFields,
                            index,
                            updateArrayElement(searchFields[index].values, i, {
                              option: searchFields[index].values[i].option,
                              startDate: e.target.value,
                              endDate: Date.now(),
                            })
                          )
                        )
                      }
                    />
                  </form>
                  {ValuePopoverFooter(i)}
                </>
              );
          }
        }
      };

      return (
        <>
          {searchFields[index].values.map((value, i) => (
            <div key={i}>
              <EuiSelect
                hasNoInitialSelection
                id="Select an option"
                options={DateOptions}
                value={searchFields[index].values[i].option}
                onChange={(e) => {
                  addFieldValue(i, e.target.value);
                  invalidateFieldValues();
                }}
              />
              {SelectDates(i)}
            </div>
          ))}
        </>
      );
    default:
  }
};

const PopoverValueButton = (
  index,
  standardFields,
  setStandardFields,
  searchFields,
  setSearchFields,
  isPopoverValueOpen,
  setIsPopoverValueOpen,
  valueError,
  setValueError,
  search,
  setSearch,
  setSearchCount,
  fieldCount,
  setFieldCount,
  selectedOperatorId,
  datePickerStyles,
  createPolicyToast,
  selectedSources,
  setSelectedSources,
  availableSources,
  setAvailableSources
) => {
  return (
    <EuiPopover
      panelPaddingSize="s"
      button={
        <EuiButtonIcon
          size="s"
          color="primary"
          onClick={() =>
            setIsPopoverValueOpen(
              updateArrayElement(isPopoverValueOpen, index, !isPopoverValueOpen[index])
            )
          }
          iconType="documentEdit"
          title="Give field values"
        />
      }
      isOpen={isPopoverValueOpen[index]}
      closePopover={() =>
        setIsPopoverValueOpen(updateArrayElement(isPopoverValueOpen, index, false))
      }
    >
      {/*<div style={{ width: 240 }}>
                <EuiButtonIcon
                    size="s"
                    style={{ float: 'right' }}
                    color="danger"
                    onClick={() => setIsPopoverValueOpen(updateArrayElement(isPopoverValueOpen, index, false))}
                    iconType="crossInACircleFilled"
                    title="Close popover"
                />
            </div>*/}
      <div style={{ width: 240 }}>
        {PopoverValueContent(
          index,
          standardFields,
          setStandardFields,
          searchFields,
          setSearchFields,
          valueError,
          setValueError,
          search,
          setSearch,
          setSearchCount,
          fieldCount,
          setFieldCount,
          isPopoverValueOpen,
          setIsPopoverValueOpen,
          selectedOperatorId,
          datePickerStyles,
          createPolicyToast,
          selectedSources,
          setSelectedSources,
          availableSources,
          setAvailableSources
        )}
      </div>
    </EuiPopover>
  );
};

const FieldsPanel = (
  standardFields,
  setStandardFields,
  searchFields,
  setSearchFields,
  selectedField,
  setSelectedField,
  selectedSection,
  setSelectedSection,
  isPopoverSelectOpen,
  setIsPopoverSelectOpen,
  isPopoverValueOpen,
  setIsPopoverValueOpen,
  valueError,
  setValueError,
  search,
  setSearch,
  setSearchCount,
  selectedOperatorId,
  setSelectedOperatorId,
  fieldCount,
  setFieldCount,
  availableSources,
  setAvailableSources,
  selectedSources,
  setSelectedSources,
  sources,
  datePickerStyles,
  createPolicyToast
) => {
  const countFieldValues = (field, index) => {
    const fieldStr = `{${fieldValuesToString(field)}}`;
    const queriesWithIndices = createAdvancedQueriesBySource(
      standardFields,
      fieldStr,
      selectedSources,
      availableSources
    );
    getQueryCount(queriesWithIndices).then((result) => {
      if (result || result === 0)
        setFieldCount(updateArrayElement(fieldCount, index, result));
    });
  };

  const handleRemoveField = (index) => {
    const updatedSearchFields = removeArrayElement(searchFields, index);
    setSearchFields(updatedSearchFields);
    updateSources(updatedSearchFields, sources, setSelectedSources, setAvailableSources);
    updateSearch(setSearch, updatedSearchFields, selectedOperatorId, setSearchCount);
  };

  const handleClearValues = (index) => {
    let updatedSearchFields = [];
    switch (searchFields[index].type) {
      case 'Text':
        updatedSearchFields = updateArrayElement(
          searchFields,
          index,
          new SearchField(
            searchFields[index].name,
            searchFields[index].type,
            '',
            false,
            searchFields[index].sources
          )
        );
        break;
      case 'List':
        updatedSearchFields = updateArrayElement(
          searchFields,
          index,
          new SearchField(
            searchFields[index].name,
            searchFields[index].type,
            [],
            false,
            searchFields[index].sources
          )
        );
        break;
      default:
        updatedSearchFields = updateArrayElement(
          searchFields,
          index,
          new SearchField(
            searchFields[index].name,
            searchFields[index].type,
            [{}],
            false,
            searchFields[index].sources
          )
        );
    }
    setSearchFields(updatedSearchFields);
    updateSources(updatedSearchFields, sources, setSelectedSources, setAvailableSources);
    setFieldCount(updateArrayElement(fieldCount, index));
    updateSearch(setSearch, updatedSearchFields, selectedOperatorId, setSearchCount);
  };

  if (standardFields === []) {
    return <h2>Loading user fields...</h2>;
  }

  return (
    <>
      <EuiTitle size="xs">
        <h2>Field search</h2>
      </EuiTitle>
      <EuiPanel paddingSize="m">
        <EuiFlexGroup direction="column">
          {searchFields.map((field, index) => (
            <EuiPanel key={'field' + index} paddingSize="s">
              <EuiFlexItem grow={false}>
                <EuiFlexGroup direction="row" alignItems="center">
                  <EuiFlexItem grow={false}>
                    <EuiButtonIcon
                      size="s"
                      color="danger"
                      onClick={() => handleRemoveField(index)}
                      iconType="indexClose"
                      title="Remove field"
                    />
                  </EuiFlexItem>
                  <EuiFlexItem>
                    {field.isValidated ? (
                      <>
                        {field.sources.length ? (
                          <EuiHealth color="danger">
                            {fieldValuesToString(field).replace(/_|\./g, ' ')}
                          </EuiHealth>
                        ) : (
                          <EuiHealth color="primary">
                            {fieldValuesToString(field).replace(/_|\./g, ' ')}
                          </EuiHealth>
                        )}
                      </>
                    ) : (
                      <>
                        {field.sources.length ? (
                          <EuiHealth color="danger">
                            {field.name.replace(/_|\./g, ' ')}
                          </EuiHealth>
                        ) : (
                          <EuiHealth color="primary">
                            {field.name.replace(/_|\./g, ' ')}
                          </EuiHealth>
                        )}
                      </>
                    )}
                  </EuiFlexItem>
                  <EuiFlexItem grow={false}>
                    {isNaN(fieldCount[index]) ? (
                      <></>
                    ) : (
                      <>
                        <EuiTextColor color="secondary">
                          {fieldCount[index]}{' '}
                          {fieldCount[index] === 1 ? 'result' : 'results'}
                        </EuiTextColor>
                      </>
                    )}
                  </EuiFlexItem>
                  <EuiFlexItem grow={false}>
                    {field.isValidated ? (
                      <>
                        <EuiButtonIcon
                          size="s"
                          onClick={() => countFieldValues(field, index)}
                          iconType="number"
                          title="Count results"
                        />
                      </>
                    ) : (
                      <></>
                    )}
                  </EuiFlexItem>
                  <EuiFlexItem grow={false}>
                    {field.isValidated ? (
                      <>
                        <EuiButtonIcon
                          size="s"
                          color="danger"
                          onClick={() => handleClearValues(index)}
                          iconType="trash"
                          title="Clear values"
                        />
                      </>
                    ) : (
                      <></>
                    )}
                  </EuiFlexItem>
                  <EuiFlexItem grow={false}>
                    {PopoverValueButton(
                      index,
                      standardFields,
                      setStandardFields,
                      searchFields,
                      setSearchFields,
                      isPopoverValueOpen,
                      setIsPopoverValueOpen,
                      valueError,
                      setValueError,
                      search,
                      setSearch,
                      setSearchCount,
                      fieldCount,
                      setFieldCount,
                      selectedOperatorId,
                      datePickerStyles,
                      createPolicyToast,
                      selectedSources,
                      setSelectedSources,
                      availableSources,
                      setAvailableSources
                    )}
                  </EuiFlexItem>
                </EuiFlexGroup>
              </EuiFlexItem>
            </EuiPanel>
          ))}
        </EuiFlexGroup>
        <EuiSpacer size="l" />
        {PopoverSelect(
          standardFields,
          setStandardFields,
          searchFields,
          setSearchFields,
          selectedField,
          setSelectedField,
          selectedSection,
          setSelectedSection,
          isPopoverSelectOpen,
          setIsPopoverSelectOpen,
          fieldCount,
          setFieldCount,
          selectedSources,
          setSelectedSources
        )}
      </EuiPanel>
      <EuiSpacer size="s" />
      <EuiRadioGroup
        options={Operators}
        idSelected={selectedOperatorId}
        onChange={(id) => {
          setSelectedOperatorId(id);
          updateSearch(setSearch, searchFields, id, setSearchCount);
        }}
        name="operators group"
        legend={{
          children: <span>Search option</span>,
        }}
      />
    </>
  );
};

const SourceSelect = (
  availableSources,
  selectedSources,
  setSelectedSources,
  sourceSelectError,
  setSourceSelectError
) => {
  if (Object.keys(availableSources).length !== 0) {
    availableSources.forEach((source) => {
      if (source.name) {
        source = changeNameToLabel(source);
      }
    });

    const onSourceChange = (selectedOptions) => {
      setSourceSelectError(undefined);
      setSelectedSources(selectedOptions);
    };

    const onSourceSearchChange = (value, hasMatchingOptions) => {
      setSourceSelectError(
        value.length === 0 || hasMatchingOptions
          ? undefined
          : `"${value}" is not a valid option`
      );
    };
    return (
      <>
        <EuiTitle size="xs">
          <h2>Partner sources</h2>
        </EuiTitle>
        <EuiSpacer size="s" />
        <EuiFlexItem>
          <EuiFormRow
            error={sourceSelectError}
            isInvalid={sourceSelectError !== undefined}
          >
            <EuiComboBox
              placeholder="By default, all sources are selected"
              options={availableSources}
              selectedOptions={selectedSources}
              onChange={onSourceChange}
              onSearchChange={onSourceSearchChange}
            />
          </EuiFormRow>
        </EuiFlexItem>
      </>
    );
  } else {
    return (
      <p>
        <EuiIcon type="alert" color="danger" /> No source available !
      </p>
    );
  }
};

const Search = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedTabNumber, setSelectedTabNumber] = useState(0);
  const [userHistory, setUserHistory] = useState({});
  const [advancedSearch, setAdvancedSearch] = useState(false);
  const [readOnlyQuery, setReadOnlyQuery] = useState(true);
  const [selectedField, setSelectedField] = useState([]);
  const [selectedSection, setSelectedSection] = useState([]);
  const [isPopoverSelectOpen, setIsPopoverSelectOpen] = useState(false);
  const [isPopoverValueOpen, setIsPopoverValueOpen] = useState([false]);
  const [selectedSources, setSelectedSources] = useState([]);
  const [availableSources, setAvailableSources] = useState([]);
  const [sourceSelectError, setSourceSelectError] = useState(undefined);
  const [valueError, setValueError] = useState(undefined);
  const [search, setSearch] = useState('');
  const [searchName, setSearchName] = useState('');
  const [searchDescription, setSearchDescription] = useState('');
  const [basicSearch, setBasicSearch] = useState('');
  const [selectedOperatorId, setSelectedOperatorId] = useState('0');
  const [searchFields, setSearchFields] = useState([]);
  const [standardFields, setStandardFields] = useState([]);
  const [sources, setSources] = useState([]);
  const [searchResults, setSearchResults] = useState();
  const [searchCount, setSearchCount] = useState();
  const [fieldCount, setFieldCount] = useState([]);
  const [isReadOnlyModalOpen, setIsReadOnlyModalOpen] = useState(false);
  const [isSaveSearchModalOpen, setIsSaveSearchModalOpen] = useState(false);
  const [selectedSavedSearch, setSelectedSavedSearch] = useState();
  const [historySelectError, setHistorySelectError] = useState(undefined);
  const [notificationToasts, setNotificationToasts] = useState([]);
  const datePickerStyles = useStyles();

  useEffect(() => {
    fetchPublicFields().then((resultStdFields) => {
      resultStdFields.forEach((field) => {
        field.sources = [];
      });
      setStandardFields(resultStdFields);
      fetchUserPolicyFields(sessionStorage.getItem('user_id')).then(
        (resultPolicyFields) => {
          const userFields = resultStdFields;
          resultPolicyFields.forEach((polField) => {
            const stdFieldIndex = userFields.findIndex(
              (stdField) => stdField.id === polField.std_id
            );
            if (stdFieldIndex >= 0) {
              if (!userFields[stdFieldIndex].sources.includes(polField.source_id))
                userFields[stdFieldIndex].sources.push(polField.source_id);
            } else {
              const newField = {
                id: polField.std_id,
                sources: [polField.source_id],
                ...polField,
              };
              userFields.push(newField);
            }
          });
          userFields.sort((a, b) => (a.id > b.id ? 1 : b.id > a.id ? -1 : 0));
          setStandardFields(removeNullFields(userFields));
        }
      );

      // policyField => {
      //     policyField.forEach(
    });
    fetchSources(sessionStorage.getItem('user_id')).then((result) => {
      setSources(result);
      setAvailableSources(result);
    });
    fetchHistory(setUserHistory);
  }, []);

  const createPolicyToast = () => {
    const toast = {
      title: 'Policy field selected',
      color: 'warning',
      iconType: 'alert',
      toastLifeTimeMs: 15000,
      text: (
        <>
          <p>You selected a private field.</p>
          <p>
            Access to this field was granted for specific sources, which means that your
            search will be restricted to those.
          </p>
          <p>Please check the sources list before searching.</p>
        </>
      ),
    };
    setNotificationToasts(notificationToasts.concat(toast));
  };

  const createEditableQueryToast = () => {
    const toast = {
      title: 'Proceed with caution',
      color: 'warning',
      iconType: 'alert',
      toastLifeTimeMs: 15000,
      text: (
        <>
          <p>
            Be aware that manually editing the query can spoil search results. The syntax
            must be respected :
          </p>
          <ul>
            Fields and their values should be put between brackets : &#123; &#125; - Make
            sure every opened bracket is properly closed
          </ul>
          <ul>
            "AND" and "OR" should be capitalized between different fields conditions and
            lowercased within a field expression
          </ul>
          <ul>Make sure to check eventual typing mistakes</ul>
        </>
      ),
    };
    setNotificationToasts(notificationToasts.concat(toast));
  };

  const removeToast = (removedToast) => {
    setNotificationToasts(
      notificationToasts.filter((toast) => toast.id !== removedToast.id)
    );
  };

  const tabsContent = [
    {
      id: 'tab1',
      name: 'Compose search',
      content: (
        <>
          {advancedSearch ? (
            <>
              <EuiFlexGroup>
                <EuiFlexItem grow={false}>
                  <EuiSpacer size="s" />
                  <EuiButtonEmpty
                    onClick={() => {
                      setAdvancedSearch(!advancedSearch);
                    }}
                  >
                    Switch to basic search
                  </EuiButtonEmpty>
                </EuiFlexItem>
              </EuiFlexGroup>
              <EuiFlexGroup>
                <EuiFlexItem>
                  <EuiSpacer size="s" />
                  {SearchBar(
                    isLoading,
                    setIsLoading,
                    search,
                    setSearch,
                    searchResults,
                    setSearchResults,
                    searchFields,
                    setSearchFields,
                    searchName,
                    setSearchName,
                    searchDescription,
                    setSearchDescription,
                    readOnlyQuery,
                    setReadOnlyQuery,
                    selectedSources,
                    setSelectedSources,
                    availableSources,
                    setAvailableSources,
                    standardFields,
                    sources,
                    setSelectedTabNumber,
                    searchCount,
                    setSearchCount,
                    setFieldCount,
                    isReadOnlyModalOpen,
                    setIsReadOnlyModalOpen,
                    isSaveSearchModalOpen,
                    setIsSaveSearchModalOpen,
                    userHistory,
                    setUserHistory,
                    selectedSavedSearch,
                    setSelectedSavedSearch,
                    historySelectError,
                    setHistorySelectError,
                    selectedOperatorId,
                    createEditableQueryToast
                  )}
                </EuiFlexItem>
              </EuiFlexGroup>
              <EuiFlexGroup>
                <EuiFlexItem>
                  <EuiSpacer size="s" />
                  {FieldsPanel(
                    standardFields,
                    setStandardFields,
                    searchFields,
                    setSearchFields,
                    selectedField,
                    setSelectedField,
                    selectedSection,
                    setSelectedSection,
                    isPopoverSelectOpen,
                    setIsPopoverSelectOpen,
                    isPopoverValueOpen,
                    setIsPopoverValueOpen,
                    valueError,
                    setValueError,
                    search,
                    setSearch,
                    setSearchCount,
                    selectedOperatorId,
                    setSelectedOperatorId,
                    fieldCount,
                    setFieldCount,
                    availableSources,
                    setAvailableSources,
                    selectedSources,
                    setSelectedSources,
                    sources,
                    datePickerStyles,
                    createPolicyToast
                  )}
                  <EuiSpacer size="s" />
                  {SourceSelect(
                    availableSources,
                    selectedSources,
                    setSelectedSources,
                    sourceSelectError,
                    setSourceSelectError
                  )}
                </EuiFlexItem>
              </EuiFlexGroup>
              <EuiGlobalToastList
                toasts={notificationToasts}
                dismissToast={removeToast}
                toastLifeTimeMs={2500}
              />
            </>
          ) : (
            <>
              <EuiFlexGroup>
                <EuiFlexItem grow={false}>
                  <EuiSpacer size="s" />
                  <EuiButtonEmpty
                    onClick={() => {
                      setAdvancedSearch(!advancedSearch);
                    }}
                  >
                    Switch to advanced search
                  </EuiButtonEmpty>
                </EuiFlexItem>
              </EuiFlexGroup>
              <EuiFlexGroup>
                <EuiFlexItem>
                  <EuiSpacer size="s" />
                  <EuiFlexGroup>
                    <EuiFlexItem>
                      <EuiFieldSearch
                        value={basicSearch}
                        onChange={(e) => setBasicSearch(e.target.value)}
                        placeholder="Search..."
                        fullWidth
                      />
                    </EuiFlexItem>
                    <EuiFlexItem grow={false}>
                      <EuiButton
                        fill
                        isDisabled={advancedSearch}
                        onClick={() => {
                          setIsLoading(true);
                          const queriesWithIndices = createBasicQueriesBySource(
                            standardFields,
                            basicSearch,
                            selectedSources,
                            availableSources
                          );
                          searchQuery(queriesWithIndices).then((result) => {
                            // sessionStorage.setItem("searchResults", JSON.stringify(result))
                            setSearchResults(result);
                            setSelectedTabNumber(1);
                            setIsLoading(false);
                          });
                        }}
                      >
                        Search
                      </EuiButton>
                    </EuiFlexItem>
                  </EuiFlexGroup>
                  {isLoading && (
                    <EuiFlexGroup>
                      <EuiFlexItem>
                        <EuiProgress postion="fixed" size="l" color="accent" />
                      </EuiFlexItem>
                    </EuiFlexGroup>
                  )}
                </EuiFlexItem>
              </EuiFlexGroup>
            </>
          )}
        </>
      ),
    },
    {
      id: 'tab3',
      name: 'Results',
      content: (
        <EuiFlexGroup>
          <EuiFlexItem>{Results(searchResults, search, basicSearch)}</EuiFlexItem>
        </EuiFlexGroup>
      ),
    },
    {
      id: 'tab2',
      name: 'Map',
      content: (
        <EuiFlexGroup>
          <EuiFlexItem>
            <EuiSpacer size="l" />
            {/*<a href="https://agroenvgeo.data.inra.fr/mapfishapp/"><img src={map} width="460" height="400" alt='Map' /></a>*/}
            <SearchMap searchResults={searchResults} />
          </EuiFlexItem>
        </EuiFlexGroup>
      ),
    },
  ];

  return (
    <>
      <EuiPageContent>
        {' '}
        {/*style={{ backgroundColor: "#fafafa" }}*/}
        <EuiPageContentHeader>
          <EuiPageContentHeaderSection>
            <EuiTitle>
              <h2>In-Sylva Metadata Search Platform</h2>
            </EuiTitle>
          </EuiPageContentHeaderSection>
        </EuiPageContentHeader>
        <EuiPageContentBody>
          <EuiForm>
            <EuiTabbedContent
              tabs={tabsContent}
              selectedTab={tabsContent[selectedTabNumber]}
              onTabClick={(tab) => {
                setSelectedTabNumber(tabsContent.indexOf(tab));
              }}
            />
          </EuiForm>
        </EuiPageContentBody>
      </EuiPageContent>
    </>
  );
};

export default Search;
