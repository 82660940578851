import { InSylvaGatekeeperClient } from '../context/InSylvaGatekeeperClient';
import { refreshToken } from '../context/UserContext';
import { tokenTimedOut } from '../Utils';

const igClient = new InSylvaGatekeeperClient();
igClient.baseUrl = process.env.REACT_APP_IN_SYLVA_GATEKEEPER_PORT
  ? `${process.env.REACT_APP_IN_SYLVA_GATEKEEPER_HOST}:${process.env.REACT_APP_IN_SYLVA_GATEKEEPER_PORT}`
  : `${window._env_.REACT_APP_IN_SYLVA_GATEKEEPER_HOST}`;

export const findOneUser = async (id, request = igClient) => {
  if (tokenTimedOut(process.env.REACT_KEYCLOAK_TOKEN_VALIDITY)) {
    refreshToken();
  }
  igClient.token = sessionStorage.getItem('access_token');
  try {
    const user = await request.findOneUser(id);
    if (user) {
      return user;
    }
  } catch (error) {
    console.error(error);
  }
};

export const findOneUserWithGroupAndRole = async (id, request = igClient) => {
  if (tokenTimedOut(process.env.REACT_KEYCLOAK_TOKEN_VALIDITY)) {
    refreshToken();
  }
  igClient.token = sessionStorage.getItem('access_token');
  try {
    const user = await request.findOneUserWithGroupAndRole(id);
    if (user) {
      return user;
    }
  } catch (error) {
    console.error(error);
  }
};

export async function getGroups() {
  if (tokenTimedOut(process.env.REACT_KEYCLOAK_TOKEN_VALIDITY)) {
    refreshToken();
  }
  igClient.token = sessionStorage.getItem('access_token');
  try {
    const groups = await igClient.getGroups();
    if (groups) {
      return groups;
    }
  } catch (error) {
    console.error(error);
  }
}

export async function getRoles() {
  if (tokenTimedOut(process.env.REACT_KEYCLOAK_TOKEN_VALIDITY)) {
    refreshToken();
  }
  igClient.token = sessionStorage.getItem('access_token');
  try {
    const roles = await igClient.findRole();
    if (roles) {
      return roles;
    }
  } catch (error) {
    console.error(error);
  }
}

export const sendMail = async (subject, message, request = igClient) => {
  if (tokenTimedOut(process.env.REACT_KEYCLOAK_TOKEN_VALIDITY)) {
    refreshToken();
  }
  igClient.token = sessionStorage.getItem('access_token');
  try {
    await request.sendMail(subject, message);
  } catch (error) {
    console.error(error);
  }
};

/* export async function findUserDetails(store, id, request = igClient) {
    try {
        store.setState({ isLoading: true });
        const userDetails = await request.getUserDetails(id);
        if (userDetails) {
            const status = "SUCCESS";
            store.setState({ userDetails, status, isLoading: false });
        }
    } catch (error) {
        const isError404 = error.response && error.response.status === 404;
        const status = isError404 ? "NOT_FOUND" : "ERROR";
        store.setState({ status, isLoading: false });
    }
} */

/* export async function findUserFields(store, kcId, request = igClient) {
    try {
        // store.setState({ isLoading: true });
        const fields = await request.getUserFields(kcId);
        if (userDetails) {
            const status = "SUCCESS";
            // store.setState({ fields, status, isLoading: false });
        }
    } catch (error) {
        const isError404 = error.response && error.response.status === 404;
        const status = isError404 ? "NOT_FOUND" : "ERROR";
        // store.setState({ status, isLoading: false });
    }
} */

export async function fetchUserRequests(kcId) {
  if (tokenTimedOut(process.env.REACT_KEYCLOAK_TOKEN_VALIDITY)) {
    refreshToken();
  }
  igClient.token = sessionStorage.getItem('access_token');
  try {
    const requests = await igClient.getUserRequests(kcId);
    if (requests) {
      return requests;
    }
  } catch (error) {
    console.error(error);
  }
}

export async function createUserRequest(kcId, message) {
  if (tokenTimedOut(process.env.REACT_KEYCLOAK_TOKEN_VALIDITY)) {
    refreshToken();
  }
  igClient.token = sessionStorage.getItem('access_token');
  try {
    await igClient.createUserRequest(kcId, message);
  } catch (error) {
    console.error(error);
  }
}

export async function deleteUserRequest(requestId) {
  if (tokenTimedOut(process.env.REACT_KEYCLOAK_TOKEN_VALIDITY)) {
    refreshToken();
  }
  igClient.token = sessionStorage.getItem('access_token');
  try {
    await igClient.deleteUserRequest(requestId);
  } catch (error) {
    console.error(error);
  }
}

export async function addUserHistory(kcId, query, name, uiStructure, description) {
  if (tokenTimedOut(process.env.REACT_KEYCLOAK_TOKEN_VALIDITY)) {
    refreshToken();
  }
  igClient.token = sessionStorage.getItem('access_token');
  try {
    const jsonUIStructure = JSON.stringify(uiStructure);
    await igClient.addUserHistory(kcId, query, name, jsonUIStructure, description);
  } catch (error) {
    console.error(error);
  }
}

export async function fetchUserHistory(kcId) {
  if (tokenTimedOut(process.env.REACT_KEYCLOAK_TOKEN_VALIDITY)) {
    refreshToken();
  }
  igClient.token = sessionStorage.getItem('access_token');
  try {
    const history = await igClient.userHistory(kcId);
    if (history) {
      return history;
    }
  } catch (error) {
    console.error(error);
  }
}

export async function deleteUserHistory(id) {
  if (tokenTimedOut(process.env.REACT_KEYCLOAK_TOKEN_VALIDITY)) {
    refreshToken();
  }
  igClient.token = sessionStorage.getItem('access_token');
  try {
    await igClient.deleteUserHistory(id);
  } catch (error) {
    console.error(error);
  }
}
