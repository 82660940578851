import { InSylvaSourceManagerClient } from '../context/InSylvaSourceManagerClient';
import { InSylvaSearchClient } from '../context/InSylvaSearchClient';
import { refreshToken } from '../context/UserContext';
import { tokenTimedOut } from '../Utils';

const ismClient = new InSylvaSourceManagerClient();
ismClient.baseUrl = process.env.REACT_APP_IN_SYLVA_SOURCE_MANAGER_PORT
  ? `${process.env.REACT_APP_IN_SYLVA_SOURCE_MANAGER_HOST}:${process.env.REACT_APP_IN_SYLVA_SOURCE_MANAGER_PORT}`
  : `${window._env_.REACT_APP_IN_SYLVA_SOURCE_MANAGER_HOST}`;
const isClient = new InSylvaSearchClient();
isClient.baseUrl = process.env.REACT_APP_IN_SYLVA_SEARCH_PORT
  ? `${process.env.REACT_APP_IN_SYLVA_SEARCH_HOST}:${process.env.REACT_APP_IN_SYLVA_SEARCH_PORT}`
  : `${window._env_.REACT_APP_IN_SYLVA_SEARCH_HOST}`;

export {
  fetchPublicFields,
  fetchUserPolicyFields,
  fetchSources,
  searchQuery,
  getQueryCount,
};

async function fetchPublicFields() {
  if (tokenTimedOut(process.env.REACT_KEYCLOAK_TOKEN_VALIDITY)) {
    refreshToken();
  }
  ismClient.token = sessionStorage.getItem('access_token');
  return await ismClient.publicFields();
}

async function fetchUserPolicyFields(kcId) {
  if (tokenTimedOut(process.env.REACT_KEYCLOAK_TOKEN_VALIDITY)) {
    refreshToken();
  }
  ismClient.token = sessionStorage.getItem('access_token');
  return await ismClient.userPolicyFields(kcId);
}

async function fetchSources(kcId) {
  if (tokenTimedOut(process.env.REACT_KEYCLOAK_TOKEN_VALIDITY)) {
    refreshToken();
  }
  ismClient.token = sessionStorage.getItem('access_token');
  return await ismClient.sourcesWithIndexes(kcId);
}

async function searchQuery(query) {
  if (tokenTimedOut(process.env.REACT_KEYCLOAK_TOKEN_VALIDITY)) {
    refreshToken();
  }
  isClient.token = sessionStorage.getItem('access_token');
  return await isClient.search(query);
}

async function getQueryCount(queries) {
  if (tokenTimedOut(process.env.REACT_KEYCLOAK_TOKEN_VALIDITY)) {
    refreshToken();
  }
  isClient.token = sessionStorage.getItem('access_token');
  return await isClient.count(queries);
}
