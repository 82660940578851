import React from 'react';
import ReactDOM from 'react-dom';
import '@elastic/eui/dist/eui_theme_light.css';
import { UserProvider, checkUserLogin } from './context/UserContext';
import App from './App';
import { getLoginUrl, getUrlParam, redirect } from './Utils.js';

const userId = getUrlParam('kcId', '');
const accessToken = getUrlParam('accessToken', '');
let refreshToken = getUrlParam('refreshToken', '');
if (refreshToken.includes('#/app/search')) {
  refreshToken = refreshToken.substring(0, refreshToken.indexOf('#'));
}
checkUserLogin(userId, accessToken, refreshToken);

if (sessionStorage.getItem('access_token')) {
  ReactDOM.render(
    <UserProvider>
      <App userId={userId} accessToken={accessToken} refreshToken={refreshToken} />
    </UserProvider>,
    document.getElementById('root')
  );
} else {
  redirect(getLoginUrl() + '?requestType=search');
}
