import React from 'react';
import {
  EuiPageContent,
  EuiPageContentHeader,
  EuiPageContentHeaderSection,
  EuiPageContentBody,
  EuiTitle,
} from '@elastic/eui';

const Home = () => {
  return (
    <>
      <EuiPageContent>
        <EuiPageContentHeader>
          <EuiPageContentHeaderSection>
            <EuiTitle>
              <h2>Welcome to the IN-SYLVA IS application search module</h2>
            </EuiTitle>
            <br />
            <br />
            <p>
              As a reminder, it should be remembered that the metadata stored in IN-SYLVA
              IS are structured around the IN-SYLVA standard.
            </p>
            <br />
            <p>
              This standard is composed of metadata fields. A metadata record is therefore
              made up of a series of fields accompanied by their value.
            </p>
            <br />
            <br />
            <p>
              With this part of the interface you will be able to search for metadata
              records (previously loaded via the portal), by defining a certain number of
              criteria.
            </p>
            <br />
            <p>
              By default the "search" interface opens to a "plain text" search, ie the
              records returned in the result are those which, in one of the field values,
              contains the supplied character string.
            </p>
            <br />
            <p>
              A click on the Advanced search button gives access to a more complete form
              via which you can do more precise searches on one or more targeted fields.
            </p>
            <br />
            <br />
            <p>Click on the "Search" tab to access the search interface.</p>
          </EuiPageContentHeaderSection>
        </EuiPageContentHeader>
        <EuiPageContentBody></EuiPageContentBody>
      </EuiPageContent>
    </>
  );
};

export default Home;
