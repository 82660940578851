import React, { useEffect, useState } from 'react';
import {
  EuiAvatar,
  EuiFlexGroup,
  EuiFlexItem,
  EuiLink,
  EuiText,
  EuiSpacer,
  EuiPopover,
  EuiButtonIcon,
} from '@elastic/eui';
import { signOut } from '../../context/UserContext';
import { findOneUser } from '../../actions/user';

export default function HeaderUserMenu() {
  const [isOpen, setIsOpen] = useState(false);
  const [user, setUser] = useState({});

  const onMenuButtonClick = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  const loadUser = () => {
    if (sessionStorage.getItem('user_id')) {
      findOneUser(sessionStorage.getItem('user_id')).then((user) => {
        setUser(user);
      });
    }
  };

  useEffect(() => {
    loadUser();
  }, []);

  const HeaderUserButton = (
    <EuiButtonIcon
      size="s"
      onClick={onMenuButtonClick}
      iconType="user"
      title="User profile"
      aria-label="User profile"
    />
  );

  return (
    user.username && (
      <EuiPopover
        id="headerUserMenu"
        ownFocus
        button={HeaderUserButton}
        isOpen={isOpen}
        anchorPosition="downRight"
        closePopover={closeMenu}
        panelPaddingSize="none"
      >
        <div style={{ width: 320 }}>
          <EuiFlexGroup gutterSize="m" className="euiHeaderProfile" responsive={false}>
            <EuiFlexItem grow={false}>
              <EuiAvatar name={user.username} size="xl" />
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiText>{user.username}</EuiText>
              <EuiSpacer size="m" />
              <EuiFlexGroup>
                <EuiFlexItem>
                  <EuiFlexGroup justifyContent="spaceBetween">
                    <EuiFlexItem grow={false}>
                      <EuiLink href="#/app/profile">Edit profile</EuiLink>
                    </EuiFlexItem>
                    <EuiFlexItem grow={false}>
                      <EuiLink onClick={() => signOut()}>Log out</EuiLink>
                    </EuiFlexItem>
                  </EuiFlexGroup>
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiFlexItem>
          </EuiFlexGroup>
        </div>
      </EuiPopover>
    )
  );
}
