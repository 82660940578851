export const Operators = [
  {
    id: '0',
    value: 'And',
    label: 'Match all criterias',
  },
  {
    id: '1',
    value: 'Or',
    label: 'Match at least one criteria',
  },
];

export const DateOptions = [
  {
    text: 'Date',
    value: '=',
  },
  {
    text: 'Before',
    value: '<=',
  },
  {
    text: 'After',
    value: '>=',
  },
  {
    text: 'Period',
    value: 'between',
  },
];

export const NumericOptions = [
  {
    text: 'Equal to',
    value: '=',
  },
  {
    text: 'Under',
    value: '<=',
  },
  {
    text: 'Over',
    value: '>=',
  },
  {
    text: 'Between',
    value: 'between',
  },
];
