class InSylvaSourceManagerClient {
  async post(method, path, requestContent) {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.token}`,
    };
    const response = await fetch(`${this.baseUrl}${path}`, {
      method,
      headers,
      body: JSON.stringify(requestContent),
      mode: 'cors',
    });
    return await response.json();
  }

  async publicFields() {
    const path = `/publicFieldList`;
    return this.post('GET', `${path}`);
  }

  async userPolicyFields(userId) {
    const path = `/policy-stdfields`;
    return this.post('POST', `${path}`, { userId });
  }

  async sourcesWithIndexes(kc_id) {
    const path = `/source_indexes`;
    return this.post('POST', `${path}`, { kc_id });
  }
}

InSylvaSourceManagerClient.prototype.baseUrl = null;
InSylvaSourceManagerClient.prototype.token = null;
export { InSylvaSourceManagerClient };
