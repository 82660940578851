import React from 'react';
import { Link } from 'react-router-dom';
import {
  EuiHeader,
  EuiHeaderSection,
  EuiHeaderSectionItem,
  EuiHeaderLinks,
  EuiHeaderLink,
} from '@elastic/eui';
import HeaderUserMenu from './header_user_menu';
import style from './styles';
import logoInSylva from '../../favicon.svg';

const structure = [
  {
    id: 0,
    label: 'Home',
    href: '/app/home',
    icon: '',
  },
  {
    id: 1,
    label: 'Search',
    href: '/app/search',
    icon: '',
  },
];

const Header = () => {
  return (
    <>
      <EuiHeader>
        <EuiHeaderSection grow={true}>
          <EuiHeaderSectionItem border="right">
            <img
              style={style}
              src={logoInSylva}
              width="75"
              height="45"
              alt="Logo INRAE"
            />
          </EuiHeaderSectionItem>
          <EuiHeaderLinks border="right">
            {structure.map((link) => (
              <EuiHeaderLink iconType="empty" key={link.id}>
                <Link to={link.href}>{link.label}</Link>
              </EuiHeaderLink>
            ))}
          </EuiHeaderLinks>
        </EuiHeaderSection>
        <EuiHeaderSection side="right">
          <EuiHeaderSectionItem>{HeaderUserMenu()}</EuiHeaderSectionItem>
        </EuiHeaderSection>
      </EuiHeader>
    </>
  );
};

export default Header;
