import React from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import Header from '../../components/Header';
import Search from '../../pages/search';
import Home from '../../pages/home';
import Profile from '../../pages/profile';

const Layout = () => {
  return (
    <>
      <Header />
      <Switch>
        <Route path="/app/home" component={Home} />
        <Route path="/app/search" component={Search} />
        <Route path="/app/profile" component={Profile} />
      </Switch>
    </>
  );
};

export default withRouter(Layout);
