import React, { useState, useEffect } from 'react';
import {
  EuiPageContent,
  EuiPageContentHeader,
  EuiPageContentHeaderSection,
  EuiPageContentBody,
  EuiTitle,
  EuiSpacer,
  EuiSelect,
  EuiButton,
  EuiFormRow,
  EuiComboBox,
  EuiBasicTable,
  EuiForm,
} from '@elastic/eui';
import {
  findOneUser,
  findOneUserWithGroupAndRole,
  getGroups,
  getRoles,
  sendMail,
  fetchUserRequests,
  createUserRequest,
  deleteUserRequest,
} from '../../actions/user';

/* const fieldsGridOptions = {
    filter: true,
    filterType: "dropdown",
    responsive: "stacked",
    selectableRows: 'multiple',
    selectableRowsOnClick: true,
    onRowsSelect: (rowsSelected, allRows) => {
    },
    onRowClick: (rowData, rowState) => {
    },
}; */

const Profile = () => {
  const [user, setUser] = useState({});
  const [userRole, setUserRole] = useState('');
  const [groups, setGroups] = useState([]);
  const [roles, setRoles] = useState([]);
  const [userGroups, setUserGroups] = useState([]);
  const [userRequests, setUserRequests] = useState([]);
  const [selectedRole, setSelectedRole] = useState();
  const [valueError, setValueError] = useState(undefined);

  useEffect(() => {
    loadUser();
    getUserRequests();
    getUserGroups();
    getUserRoles();
  }, []);

  const groupColumns = [
    { field: 'label', name: 'Group Name', width: '30%' },
    { field: 'description', name: 'Group description' },
  ];

  const getUserRoles = () => {
    getRoles().then((rolesResult) => {
      const rolesArray = [];
      rolesResult.forEach((role) => {
        rolesArray.push({ id: role.id, text: role.name });
      });
      setRoles(rolesArray);
    });
  };

  const getUserGroups = () => {
    getGroups().then((groupsResult) => {
      const groupsArray = [];
      groupsResult.forEach((group) => {
        groupsArray.push({
          id: group.id,
          label: group.name,
          description: group.description,
        });
      });
      setGroups(groupsArray);
    });
  };

  const getUserRequests = () => {
    fetchUserRequests(sessionStorage.getItem('user_id')).then((result) => {
      setUserRequests([...result]);
    });
  };

  const onDeleteRequest = async (request) => {
    const request_id = request.id;
    await deleteUserRequest(request_id);
    getUserRequests();
    getUserGroups();
    getUserRoles();
  };

  const requestActions = [
    {
      name: 'Cancel',
      description: 'Cancel this request',
      icon: 'trash',
      type: 'icon',
      onClick: onDeleteRequest,
    },
  ];

  const requestsColumns = [
    { field: 'request_message', name: 'Message', width: '90%' },
    { field: 'is_processed', name: 'Processed' },
    { name: 'Delete', actions: requestActions },
  ];

  const loadUser = () => {
    if (sessionStorage.getItem('user_id')) {
      findOneUser(sessionStorage.getItem('user_id')).then((user) => {
        setUser(user);
      });
      findOneUserWithGroupAndRole(sessionStorage.getItem('user_id')).then((result) => {
        const userGroupList = userGroups;
        result.forEach((user) => {
          if (user.groupname) {
            userGroupList.push({
              id: user.groupid,
              label: user.groupname,
              description: user.groupdescription,
            });
          }
          setUserRole(user.rolename);
        });
        setUserGroups(userGroupList);
      });
    }
  };

  const getUserGroupLabels = () => {
    let labelList = '';
    if (!!userGroups) {
      userGroups.forEach((group) => {
        labelList = `${labelList} ${group.label},`;
      });
      if (labelList.endsWith(',')) {
        labelList = labelList.substring(0, labelList.length - 1);
      }
    }
    return labelList;
  };

  const onValueSearchChange = (value, hasMatchingOptions) => {
    setValueError(
      value.length === 0 || hasMatchingOptions
        ? undefined
        : `"${value}" is not a valid option`
    );
  };

  return (
    <>
      <EuiPageContent>
        <EuiPageContentHeader>
          <EuiPageContentHeaderSection>
            <EuiTitle>
              <h2>Profile management</h2>
            </EuiTitle>
          </EuiPageContentHeaderSection>
        </EuiPageContentHeader>
        <EuiPageContentBody>
          <EuiForm component="form">
            <EuiTitle size="s">
              <h3>Group list</h3>
            </EuiTitle>
            <EuiFormRow fullWidth label="">
              <EuiBasicTable items={groups} columns={groupColumns} />
            </EuiFormRow>
            <EuiSpacer size="l" />
            <EuiTitle size="s">
              <h3>Requests list</h3>
            </EuiTitle>
            <EuiFormRow fullWidth label="">
              <EuiBasicTable items={userRequests} columns={requestsColumns} />
            </EuiFormRow>
            <EuiSpacer size="l" />
            <EuiTitle size="s">
              <h3>Request group assignment modifications</h3>
            </EuiTitle>
            {getUserGroupLabels() ? (
              <p>
                You currently belong to (or have a pending demand for) these groups :{' '}
                {getUserGroupLabels()}{' '}
              </p>
            ) : (
              <p>You currently belong to no group</p>
            )}
            <EuiFormRow error={valueError} isInvalid={valueError !== undefined}>
              <EuiComboBox
                placeholder={'Select groups'}
                options={groups}
                selectedOptions={userGroups}
                onChange={(selectedOptions) => {
                  setValueError(undefined);
                  setUserGroups(selectedOptions);
                }}
                onSearchChange={onValueSearchChange}
              />
            </EuiFormRow>
            <EuiSpacer size="m" />
            <EuiButton
              onClick={() => {
                if (userGroups) {
                  const groupList = [];
                  userGroups.forEach((group) => {
                    groupList.push(group.label);
                  });
                  const message = `The user ${user.username} (${user.email}) has made a request to be part of these groups : ${groupList}.`;
                  createUserRequest(user.id, message);
                  sendMail('User group request', message);
                  alert('Your group request has been sent to the administrators.');
                }
                getUserRequests();
              }}
              fill
            >
              Send request
            </EuiButton>
            <EuiSpacer size="l" />
            <EuiTitle size="s">
              <h3>Request an application role</h3>
            </EuiTitle>
            {userRole ? (
              <p>Your current role is (or have a pending demand for) {userRole}</p>
            ) : (
              <></>
            )}
            <EuiFormRow>
              <EuiSelect
                hasNoInitialSelection
                options={roles}
                value={selectedRole}
                onChange={(e) => {
                  setSelectedRole(e.target.value);
                }}
              />
            </EuiFormRow>
            <EuiSpacer size="m" />
            <EuiButton
              onClick={() => {
                if (selectedRole) {
                  const message = `The user ${user.username} (${user.email}) has made a request to get the role : ${selectedRole}.`;
                  createUserRequest(user.id, message);
                  sendMail('User role request', message);
                  alert('Your role request has been sent to the administrators.');
                }
                getUserRequests();
              }}
              fill
            >
              Send request
            </EuiButton>
          </EuiForm>
        </EuiPageContentBody>
      </EuiPageContent>
    </>
  );
};

export default Profile;
