class InSylvaGatekeeperClient {
  async post(method, path, requestContent) {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.token}`,
    };
    const response = await fetch(`${this.baseUrl}${path}`, {
      method,
      headers,
      body: JSON.stringify(requestContent),
      mode: 'cors',
    });
    return await response.json();
  }

  async getGroups() {
    const path = `/user/groups`;
    return await this.post('POST', `${path}`, {});
  }

  async getUserRequests(kcId) {
    const path = `/user/list-requests-by-user`;
    return await this.post('POST', `${path}`, {
      kcId,
    });
  }

  async createUserRequest(kcId, message) {
    const path = `/user/create-request`;
    return await this.post('POST', `${path}`, {
      kcId,
      message,
    });
  }

  async deleteUserRequest(id) {
    const path = `/user/delete-request`;
    return await this.post('DELETE', `${path}`, { id });
  }

  async findRole() {
    const path = `/role/find`;
    return await this.post('GET', `${path}`);
  }

  async kcId({ email }) {
    const path = `/user/kcid`;
    return await this.post('POST', `${path}`, {
      email,
    });
  }

  async sendMail(subject, message) {
    const path = `/user/send-mail`;

    await this.post('POST', `${path}`, {
      subject,
      message,
    });
  }

  async findOneUser(id) {
    const path = `/user/findOne`;
    return await this.post('POST', `${path}`, {
      id,
    });
  }

  async findOneUserWithGroupAndRole(id) {
    const path = `/user/one-with-groups-and-roles`;
    return await this.post('POST', `${path}`, {
      id,
    });
  }

  async getUserDetails(kcId) {
    const path = `/user/detail`;
    return await this.post('GET', `${path}`, {
      kcId,
    });
  }

  async addUserHistory(kcId, query, name, uiStructure, description) {
    const path = `/user/add-history`;
    await this.post('POST', `${path}`, {
      kcId,
      query,
      name,
      uiStructure,
      description,
    });
  }

  async userHistory(kcId) {
    const path = `/user/fetch-history`;
    return await this.post('POST', `${path}`, {
      kcId,
    });
  }

  async deleteUserHistory(id) {
    const path = `/user/delete-history`;
    await this.post('POST', `${path}`, {
      id,
    });
  }
}

InSylvaGatekeeperClient.prototype.baseUrl = null;
InSylvaGatekeeperClient.prototype.token = null;
export { InSylvaGatekeeperClient };
